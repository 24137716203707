// ==========================================================================
// Layout
// ==========================================================================
// default breakpoint
$default-breakpoint: "lg" !default;
// default wrapper width
$wrapper-padding: 20px !default;
$wrapper-width: 1000px !default;
$wrapper-width-wide: 1366px !default;
$wrapper-width-max: 1600px !default;
// default grid system
$default-grid-gutter: 30px !default;
$default-grid-gutter-md: 15px !default;
$default-grid-margin-bottom: 30px;
$default-grid-margin-bottom-md: 15px;
$default-grid-gutter-wide: 50px !default;
$default-grid-gutter-wide-md: 15px !default;
$default-grid-margin-bottom-wide: 30px;
$default-grid-margin-bottom-wide-md: 15px;
// ==========================================================================
// Z-index
// ==========================================================================
$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
$zindex-frontmost: 9999 !default;
// ==========================================================================
// Color
// ==========================================================================
$color-base: #fff !default;
//$color-brand: #b8100a !default;
$color-brand: #E6140A !default;
$color-brand-accent: #BB1911 !default;
$color-text: #000;
$color-link: #000 !default;
$color-link-hover: #333 !default;
$color-selected-text-base: #333 !default;
$color-selected-text: #fff !default;
// ==========================================================================
// Typography
// ==========================================================================
// defalt font set
$font-family-serif: $font-family-serif-6 !default;
$font-family-sans-serif: $font-family-sans-serif-7 !default;
// フォントセットは_font-family.scssを参照
// !! _web-fonts.scssでwebfontが読み込まれているか確認すること  !!
$font-family-eng: Helvetica, sans-serif !default;
$font-size: (
    "sm": 13,
    "md": 15,
    "default": 14
) !default;
$font-size-secondary: 0.85em !default;
$font-letter-spacing: normal !default;
$font-line-height: 1 !default;
$font-line-height-half: 25 / 14 * 0.75 !default;
$font-ls-lg: 0.125em !default;
$font-ls-md: 0.1em !default;
$font-ls-sm: 0.05em !default;
$font-ls-default: 0em !default;/*normal*/
// exsample
// $font-ls-default: $font-ls-sm;
$font-weight: 400 !default;
// ==========================================================================
// Form
// ==========================================================================
$form-border-radius: 3px;
$form-transition-duration: 0.3s;
$form-transition-property: border-color, box-shadow, background-color;
$form-placeholder-color: #767676;
$form-disabled-cursor: not-allowed;
$form-disabled-opacity: 0.5;
$form-disabled-background-color: #ddd;
// ==========================================================================
// Option
// ==========================================================================
// Wordoress使う場合trueに変更
// object/scope/wordpress.scssを参照
$wordpress: true;
// タブレットサイズ時にPC表示を横スクロールさせる場合はtrue
// 完全リキッドレイアウトの場合はfalse
$tablet-control: true;
// プリセットアニメーションを使う場合trueに変更
// foundation/animation/animation.scssを参照
$animation: false;
// リップルエフェクトを使う場合trueに変更
$ripple: false;
