// @desc - メディアクエリを`max-width`で挿入します。（デスクトップファースト）
// @param {String} $breakpoint [$default-breakpoint] - 引数に変数のキーワードを渡します。
// @see - $breakpoint-down
// @example scss - Usage
// .foo {
//   color: blue;
//   @include _mq-down() { // 引数を省略（初期値は）
//     color: yellow;
//   }
//   @include _mq-down(md) { // 引数を個別に指定
//     color: red;
//   }
// }
// @example css - CSS output
// .foo {
//   color: blue;
// }
// @media screen and (min-width: 768px) { /* `mq()` */
//   .foo {
//     color: yellow;
//   }
// }
// @media screen and (min-width: 1000px) { /* `mq(lg)` */
//   .foo {
//     color: red;
//   }
// }
@mixin _mq-down($breakpoint: $default-breakpoint) {
    @if map-has-key($breakpoint-down, $breakpoint) {
        @media #{inspect(map-get($breakpoint-down, $breakpoint))} {
            @content;
        }
    }
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "+"Please make sure it is defined in `$breakpoint-down` map.";
    }
}
