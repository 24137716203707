@charset "UTF-8";
/*normal*/
#fullpage {
  z-index: 1; }

body #fp-nav {
  /*position: fixed;*/
  margin-top: 0 !important;
  left: 1.83016vw;
  top: 60% !important; }
  body #fp-nav ul li a span {
    height: 12px;
    width: 12px;
    margin: -6px 0 0 -6px;
    border-radius: 100%;
    background: #333;
    opacity: 0.2; }
  body #fp-nav ul li:hover a span {
    height: 12px;
    width: 12px;
    margin: -6px 0 0 -6px;
    border-radius: 100%; }
  body #fp-nav ul li a.active span {
    background: #333;
    opacity: 1; }
  body #fp-nav ul li {
    margin: 10px 0 0 0; }
  body #fp-nav ul li:first-child {
    margin: 0 !important; }
  @media screen and (max-width: 499px) {
    body #fp-nav {
      top: 50% !important;
      left: 4vw; } }

.news-box {
  transition: 0.2s; }

body:not(.fp-viewing-0) .news-box {
  opacity: 0;
  z-index: -1; }

.news-box {
  width: 100%;
  max-width: 62.22548vw;
  position: absolute;
  left: 0;
  bottom: 12%;
  z-index: 10;
  background: #E6140A;
  background: linear-gradient(135deg, #E6140A 0%, #8c0005 100%);
  display: flex;
  align-items: center;
  padding: 0 0 0 2.63543vw; }
  @media screen and (min-width: 1366px) {
    .news-box {
      max-width: 850px;
      padding: 0 0 0 36px; } }
  @media screen and (max-width: 499px) {
    .news-box {
      display: block;
      bottom: 0;
      padding: 0;
      max-width: 100%;
      height: 22.66667vw;
      /*&:before {
            content: "";
            display: block;
            width: 100%;
            height: 30px;
            background: rgba(0, 0, 0, .3);
        }*/ } }

.news-head {
  color: #fff;
  font-weight: bold;
  font-size: 1.1713vw;
  letter-spacing: 0.2em; }
  @media screen and (min-width: 1366px) {
    .news-head {
      font-size: 16px; } }
  @media screen and (max-width: 499px) {
    .news-head {
      font-size: 4.26667vw;
      background: rgba(0, 0, 0, 0.3);
      height: 8vw;
      padding: 1.86667vw 0 1.86667vw 4vw; } }

.news-list-box {
  width: 5.12445vw;
  height: 5.12445vw;
  flex-shrink: 0;
  background-color: #BB1911;
  position: absolute;
  right: 0; }
  @media screen and (min-width: 1366px) {
    .news-list-box {
      width: 70px;
      height: 70px; } }
  @media screen and (max-width: 499px) {
    .news-list-box {
      width: 18.66667vw;
      height: 8vw;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0; } }
  .news-list-box .news-trigger {
    height: auto;
    display: block;
    width: 1.46413vw;
    margin: 1.31772vw auto 0; }
    .news-list-box .news-trigger img {
      display: block; }
    @media screen and (min-width: 1366px) {
      .news-list-box .news-trigger {
        width: 20px;
        margin: 18px auto 0; } }
    @media screen and (max-width: 499px) {
      .news-list-box .news-trigger {
        margin: 0;
        width: 4.26667vw;
        height: 3.2vw;
        position: absolute;
        right: 2.66667vw; } }
  .news-list-box a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 0.73206vw; }
    @media screen and (min-width: 1366px) {
      .news-list-box a {
        font-size: 10px; } }
    @media screen and (max-width: 499px) {
      .news-list-box a {
        font-size: 2.66667vw; } }
    .news-list-box a span {
      position: absolute;
      right: 0;
      bottom: 1.24451vw;
      left: 0;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      letter-spacing: 0.1em;
      padding-left: 0.1em; }
      @media screen and (min-width: 1366px) {
        .news-list-box a span {
          bottom: 17px; } }
      @media screen and (max-width: 499px) {
        .news-list-box a span {
          top: 0;
          bottom: 0;
          left: 2.66667vw;
          right: auto;
          margin: auto 0;
          align-items: center; } }

#news .news-swiper-wrap {
  height: 5.12445vw;
  width: 48.53587vw;
  position: relative;
  margin: 0 0.73206vw;
  z-index: 0; }
  @media screen and (min-width: 1366px) {
    #news .news-swiper-wrap {
      height: 70px;
      width: 663px;
      margin: 0 10px; } }
  @media screen and (max-width: 499px) {
    #news .news-swiper-wrap {
      width: 96%;
      margin: 0 2%;
      height: 14.66667vw; } }

#news .news-swiper {
  overflow: hidden;
  margin: 0 3.07467vw; }
  @media screen and (min-width: 1366px) {
    #news .news-swiper {
      margin: 0 42px; } }
  @media screen and (max-width: 499px) {
    #news .news-swiper {
      margin: 0 30px; } }

#news .swiper-slide a {
  display: block;
  height: 5.12445vw;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0 0.36603vw;
  font-size: 1.02489vw; }
  @media screen and (min-width: 1366px) {
    #news .swiper-slide a {
      height: 70px;
      font-size: 14px;
      padding: 0 5px; } }
  @media screen and (max-width: 499px) {
    #news .swiper-slide a {
      font-size: 3.46667vw;
      padding: 0 1.33333vw;
      display: flex;
      align-items: center;
      height: 14.66667vw; } }

#news .swiper-slide p {
  line-height: 1.4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 2.78184vw;
  overflow: hidden; }
  @media screen and (min-width: 1366px) {
    #news .swiper-slide p {
      max-height: 38px; } }
  @media screen and (max-width: 499px) {
    #news .swiper-slide p {
      max-height: 9.6vw;
      font-size: 3.2vw; } }

#news .swiper-slide time {
  letter-spacing: 0.05em;
  margin-right: 2.19619vw; }
  @media screen and (min-width: 1366px) {
    #news .swiper-slide time {
      margin-right: 30px; } }
  @media screen and (max-width: 499px) {
    #news .swiper-slide time {
      margin: 0 4vw 0 0;
      font-size: 3.2vw; } }

#news .swiper-button-prev.news {
  left: 0;
  background: url(../img/common/arrow-l-white.svg) center center/0.51245vw 0.80527vw no-repeat; }
  @media screen and (max-width: 499px) {
    #news .swiper-button-prev.news {
      background-size: 1.86667vw 2.93333vw; } }

#news .swiper-button-next.news {
  right: 0;
  background: url(../img/common/arrow-r-white.svg) center center/0.51245vw 0.80527vw no-repeat; }
  @media screen and (max-width: 499px) {
    #news .swiper-button-next.news {
      background-size: 1.86667vw 2.93333vw; } }

#fullpage .swiper {
  height: 100%; }

#fullpage .swiper-wrapper {
  width: 100%;
  height: 100%; }

#fullpage .swiper-slide {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; }

#fullpage .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  margin: 0 0 0 10px;
  background: #333; }

#fullpage .swiper-pagination-bullet:first-child {
  margin: 0 !important; }

#fullpage .swiper-slide:nth-child(3n + 1) {
  /*1、4、7、3n+1枚目の背景色 */ }

#fullpage .swiper-slide:nth-child(3n + 2) {
  /*2、5、8、3n+2枚目の背景色 */ }

#fullpage .swiper-slide:nth-child(3n + 3) {
  /*3、6、9、3n+3枚目の背景色 */ }

#fullpage .swiper-button-next, #fullpage .swiper-button-prev {
  position: absolute;
  top: auto;
  bottom: 30px;
  width: 55px;
  height: auto;
  margin-top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 10;
  cursor: pointer; }

#fullpage .swiper-button-next {
  background: url("../../assets/img/common/arrow-r-black.svg") right center/7px 11px no-repeat;
  left: 200px;
  padding-right: 20px;
  line-height: 1;
  text-align: right; }
  #fullpage .swiper-button-next::after {
    content: '次へ';
    line-height: 1; }

#fullpage .swiper-button-prev {
  background: url("../../assets/img/common/arrow-l-black.svg") left center/7px 11px no-repeat;
  right: 200px;
  padding-left: 20px; }
  #fullpage .swiper-button-prev::after {
    content: '前へ'; }

#fullpage .swiper-container-horizontal > .swiper-pagination-bullets, #fullpage .swiper-pagination-custom, #fullpage .swiper-pagination-fraction {
  bottom: 30px; }

.Inr {
  width: 0;
  height: 0;
  position: absolute; }

.Container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }

.Container-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  height: 100%; }

body:not(.fp-viewing-0):not(.fp-viewing-1) .mainvisual {
  display: none; }

section.sec-Feature,
section.sec-Course,
section.sec-Support,
section.sec-Facility,
section.sec-Opencampus,
section.sec-HighSchool,
section.sec-BisenTube,
section.sec-Footer {
  background-color: #fff; }

.swiper-slide {
  min-height: auto; }

.mainvisual {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: url("../../assets/img/home/bg_mainvisual_pc.jpg") center top/100vw 56.22255vw no-repeat;
  position: fixed;
  z-index: -1; }
  @media screen and (max-width: 767px) {
    .mainvisual {
      height: calc(var(--vh, 1vh) * 100); } }
  @media screen and (max-width: 499px) {
    .mainvisual {
      background: url("../../assets/img/home/bg_mainvisual_sp.jpg") center center/cover no-repeat; } }
  .mainvisual .Container .text-01 {
    width: 45.24158vw;
    max-width: 618px;
    position: absolute;
    left: -43.92387vw;
    top: -32.55208vh; }
    @media screen and (min-width: 1366px) {
      .mainvisual .Container .text-01 {
        left: -600px; } }
    @media screen and (max-width: 499px) {
      .mainvisual .Container .text-01 {
        width: 40vw;
        left: -42.66667vw;
        top: -32.98351vh; } }
  .mainvisual .Container .text-02 {
    width: 29.28258vw;
    max-width: 400px;
    position: absolute;
    left: -43.92387vw;
    top: -7.32064vw; }
    @media screen and (min-width: 1366px) {
      .mainvisual .Container .text-02 {
        width: 400px;
        left: -600px;
        top: -100px; } }
    @media screen and (max-width: 499px) {
      .mainvisual .Container .text-02 {
        width: 61.33333vw;
        left: -42.66667vw;
        top: 9.74513vh; } }

@media screen and (min-width: 500px) {
  .sec-Feature .slide-top {
    background: url("../../assets/img/home/bg_feature_pc.jpg") center center/cover no-repeat; } }

@media screen and (max-width: 499px) {
  .sec-Feature .slide-top {
    background: url("../../assets/img/home/bg_feature_sp.jpg") center center/cover no-repeat; } }

.sec-Feature .slide-top .Container .inr .text-future {
  display: block;
  width: 30.01464vw;
  margin: 0 auto; }
  @media screen and (min-width: 1366px) {
    .sec-Feature .slide-top .Container .inr .text-future {
      width: 410px; } }
  @media screen and (max-width: 499px) {
    .sec-Feature .slide-top .Container .inr .text-future {
      width: 64.53333vw; } }

.sec-Feature .slide-top .Container .text {
  width: 30.74671vw;
  font-size: 1.02489vw;
  line-height: 2.42857;
  margin-top: 1.46413vw;
  letter-spacing: 0.1em; }
  @media screen and (min-width: 1366px) {
    .sec-Feature .slide-top .Container .text {
      width: 420px;
      font-size: 14px;
      margin-top: 20px; } }
  @media screen and (max-width: 499px) {
    .sec-Feature .slide-top .Container .text {
      margin-top: 18px;
      font-size: 3.2vw;
      line-height: 2.25;
      width: 84vw; } }

@media screen and (min-width: 500px) {
  .sec-Feature .slide02 {
    background: url("../../assets/img/home/bg_feature-slide_pc.jpg") center center/cover no-repeat; } }

@media screen and (max-width: 499px) {
  .sec-Feature .slide02 {
    background: url("../../assets/img/home/bg_feature-slide_sp.jpg") right center/cover no-repeat; } }

.sec-Feature .slide03, .sec-Feature .slide04 {
  background: url("../../assets/img/home/bg_feature-slide-tips.png") center/10px 10px repeat; }

.sec-Feature .slide-wrap .contents__box {
  width: 58.56515vw;
  height: 25.62225vw;
  background: rgba(255, 255, 255, 0.8);
  position: relative;
  box-sizing: border-box;
  margin-top: 4.39239vw;
  padding-top: 4.02635vw; }
  @media screen and (min-width: 1366px) {
    .sec-Feature .slide-wrap .contents__box {
      width: 800px;
      height: 350px;
      padding: 60px;
      margin-top: 60px; } }
  @media screen and (max-width: 499px) {
    .sec-Feature .slide-wrap .contents__box {
      width: 80vw;
      height: 90.66667vw;
      padding: 10.66667vw 6.66667vw 8vw;
      margin-top: 24vw; } }
  .sec-Feature .slide-wrap .contents__box::before {
    content: '';
    width: 58.56515vw;
    height: 8.34553vw;
    background: url("../../assets/img/home/txt_feature.svg") center center/contain no-repeat;
    position: absolute;
    top: -9.15081vw;
    left: 0; }
    @media screen and (min-width: 1366px) {
      .sec-Feature .slide-wrap .contents__box::before {
        width: 800px;
        height: 114px;
        top: -125px; } }
    @media screen and (max-width: 499px) {
      .sec-Feature .slide-wrap .contents__box::before {
        width: 93.86667vw;
        height: 13.33333vw;
        left: 50%;
        transform: translateX(-50%);
        top: -20vw; } }
  .sec-Feature .slide-wrap .contents__box::after {
    content: '“キンビ”の特色';
    font-size: 1.46413vw;
    font-weight: bold;
    letter-spacing: 0.2em;
    padding-left: 0.2em;
    position: absolute;
    top: -5.85652vw;
    left: 0;
    right: 0;
    margin: 0 auto; }
    @media screen and (min-width: 1366px) {
      .sec-Feature .slide-wrap .contents__box::after {
        font-size: 20px;
        top: -80px; } }
    @media screen and (max-width: 499px) {
      .sec-Feature .slide-wrap .contents__box::after {
        font-size: 4.26667vw;
        top: -16vw; } }
  .sec-Feature .slide-wrap .contents__box .num {
    display: inline-block;
    position: absolute;
    top: -2.56223vw;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 5.12445vw;
    font-weight: bold;
    color: #E6140A; }
    @media screen and (min-width: 1366px) {
      .sec-Feature .slide-wrap .contents__box .num {
        top: -35px;
        font-size: 70px; } }
    @media screen and (max-width: 499px) {
      .sec-Feature .slide-wrap .contents__box .num {
        top: -7.33333vw;
        font-size: 14.66667vw; } }
  .sec-Feature .slide-wrap .contents__box h3 {
    font-size: 1.83016vw;
    font-weight: 700;
    line-height: 1.6;
    letter-spacing: 0.15em;
    height: 5.85652vw;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media screen and (min-width: 1366px) {
      .sec-Feature .slide-wrap .contents__box h3 {
        font-size: 25px;
        height: 80px; } }
    @media screen and (max-width: 499px) {
      .sec-Feature .slide-wrap .contents__box h3 {
        height: auto;
        padding: 2.4vw 0;
        font-size: 5.33333vw;
        line-height: 1.5;
        letter-spacing: 0.1em; } }
  .sec-Feature .slide-wrap .contents__box .lead {
    /*display: inline-block;
                width: _vw(570, 1366);
                @include _mq-up(xxl) {
                    width: 570px;
                }
                @include _mq-down(sm) {
                    width: auto;
                }*/ }
    .sec-Feature .slide-wrap .contents__box .lead.image {
      margin-top: 2.92826vw; }
      @media screen and (min-width: 1366px) {
        .sec-Feature .slide-wrap .contents__box .lead.image {
          margin-top: 40px; } }
    .sec-Feature .slide-wrap .contents__box .lead.text {
      margin-top: 1.0981vw;
      font-size: 1.02489vw;
      line-height: 2.42857;
      letter-spacing: 0.1em; }
      @media screen and (min-width: 1366px) {
        .sec-Feature .slide-wrap .contents__box .lead.text {
          font-size: 14px;
          margin-top: 15px; } }
      @media screen and (max-width: 499px) {
        .sec-Feature .slide-wrap .contents__box .lead.text {
          font-size: 3.2vw;
          line-height: 2.2;
          letter-spacing: 0.05em;
          text-align: justify; } }
  .sec-Feature .slide-wrap .contents__box .top-btn-column {
    position: absolute;
    bottom: -2.19619vw;
    left: 0;
    right: 0;
    margin: 0 auto; }
    .sec-Feature .slide-wrap .contents__box .top-btn-column .top-btn-box {
      height: 4.39239vw;
      padding: 1.46413vw; }
    @media screen and (min-width: 1366px) {
      .sec-Feature .slide-wrap .contents__box .top-btn-column {
        bottom: -30px; }
        .sec-Feature .slide-wrap .contents__box .top-btn-column .top-btn-box {
          height: 60px;
          padding: 20px; } }
    @media screen and (max-width: 499px) {
      .sec-Feature .slide-wrap .contents__box .top-btn-column {
        display: flex;
        width: 68vw;
        bottom: -8vw; }
        .sec-Feature .slide-wrap .contents__box .top-btn-column .top-btn-box {
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 3.2vw;
          height: 16vw; }
        .sec-Feature .slide-wrap .contents__box .top-btn-column .top-btn-box + .top-btn-box {
          margin-top: 0;
          margin-left: 2.66667vw; } }

/**
* リンクボタン
**/
.btn-center {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2.12299vw;
  display: inline-block;
  letter-spacing: 0.1em;
  padding-left: 0.1em; }
  @media screen and (min-width: 1366px) {
    .btn-center {
      bottom: -29px; } }
  @media screen and (max-width: 499px) {
    .btn-center {
      bottom: -6.66667vw; } }
  .btn-center a {
    font-size: 1.02489vw;
    display: inline-block;
    width: 18.30161vw;
    height: 4.39239vw;
    padding: 1.68375vw 0.73206vw;
    box-sizing: border-box; }
    @media screen and (min-width: 1366px) {
      .btn-center a {
        font-size: 14px;
        width: 250px;
        height: 60px;
        padding: 23px 10px; } }
    @media screen and (max-width: 499px) {
      .btn-center a {
        font-size: 3.2vw;
        width: 53.33333vw;
        height: 13.33333vw;
        padding: 5.06667vw 2.66667vw; } }
    .btn-center a.c-gradation {
      color: #fff;
      background: #E6140A;
      background: linear-gradient(135deg, #E6140A 0%, #8c0005 100%); }
    .btn-center a.c-hair {
      color: #00439b;
      background: #fff; }
    .btn-center a.c-beauty {
      color: #ea81a2;
      background: #fff; }

.btn-white {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2.12299vw;
  display: inline-block; }
  @media screen and (min-width: 1366px) {
    .btn-white {
      bottom: -29px; } }
  @media screen and (max-width: 499px) {
    .btn-white {
      bottom: -6.66667vw; } }

.sec-Course .slide-top {
  background: #fff; }
  @media screen and (max-width: 499px) {
    .sec-Course .slide-top .Container {
      flex-flow: column; } }
  .sec-Course .slide-top .course {
    width: 50vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .sec-Course .slide-top .course.course-hb {
      background: #7fa1cd; }
      @media screen and (max-width: 499px) {
        .sec-Course .slide-top .course.course-hb {
          width: 100vw;
          height: 50%;
          padding-top: 60px;
          box-sizing: border-box; } }
      .sec-Course .slide-top .course.course-hb > .text-bg {
        width: 32.35725vw;
        height: 25.25622vw; }
        @media screen and (min-width: 1366px) {
          .sec-Course .slide-top .course.course-hb > .text-bg {
            width: 442px;
            height: 345px; } }
        @media screen and (max-width: 499px) {
          .sec-Course .slide-top .course.course-hb > .text-bg {
            width: 63.46667vw;
            height: 53.86667vw;
            opacity: 0.7;
            display: flex;
            align-items: center; }
            .sec-Course .slide-top .course.course-hb > .text-bg img {
              height: 30.28486vh; } }
    .sec-Course .slide-top .course.course-bm {
      background: #f4c0d0; }
      @media screen and (max-width: 499px) {
        .sec-Course .slide-top .course.course-bm {
          width: 100vw;
          height: 50%;
          padding-bottom: 60px;
          box-sizing: border-box; } }
      .sec-Course .slide-top .course.course-bm > .text-bg {
        width: 35.79795vw;
        height: 25.25622vw; }
        @media screen and (min-width: 1366px) {
          .sec-Course .slide-top .course.course-bm > .text-bg {
            height: 345px;
            width: 489px; } }
        @media screen and (max-width: 499px) {
          .sec-Course .slide-top .course.course-bm > .text-bg {
            width: 70.13333vw;
            height: 53.86667vw;
            opacity: 0.7;
            display: flex;
            align-items: center; }
            .sec-Course .slide-top .course.course-bm > .text-bg img {
              height: 30.28486vh; } }
    .sec-Course .slide-top .course .contents__top {
      position: absolute;
      width: 35.87116vw; }
      @media screen and (max-width: 499px) {
        .sec-Course .slide-top .course .contents__top {
          width: 100%; } }
      .sec-Course .slide-top .course .contents__top > img {
        width: 48vw;
        height: 5.33333vw;
        display: inline-block; }
      .sec-Course .slide-top .course .contents__top .tit {
        font-size: 2.85505vw;
        font-weight: 700;
        letter-spacing: 0.2em;
        margin-bottom: 1.0981vw;
        padding-top: 2.19619vw; }
        @media screen and (min-width: 1366px) {
          .sec-Course .slide-top .course .contents__top .tit {
            font-size: 39px;
            margin-bottom: 15px;
            padding-top: 30px; } }
        @media screen and (max-width: 499px) {
          .sec-Course .slide-top .course .contents__top .tit {
            font-size: 6.66667vw;
            margin-bottom: 2.66667vw; } }
      .sec-Course .slide-top .course .contents__top p {
        font-size: 1.0981vw;
        letter-spacing: 0.1em;
        line-height: 2; }
        @media screen and (min-width: 1366px) {
          .sec-Course .slide-top .course .contents__top p {
            font-size: 15px; } }
        @media screen and (max-width: 499px) {
          .sec-Course .slide-top .course .contents__top p {
            font-size: 2.66667vw;
            line-height: 1; } }
  .sec-Course .slide-top .course-circle {
    position: absolute;
    width: 12.4451vw;
    height: 12.4451vw;
    background: #E6140A;
    border-radius: 50%;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center; }
    .sec-Course .slide-top .course-circle::before {
      content: '';
      width: 3.29429vw;
      height: 0.73206vw;
      position: absolute;
      top: 1.53734vw;
      background: url("../../assets/img/home/icon_arrow_gray.svg") center center/contain no-repeat; }
    .sec-Course .slide-top .course-circle::after {
      content: '';
      width: 3.29429vw;
      height: 0.73206vw;
      position: absolute;
      bottom: 1.1713vw;
      background: url("../../assets/img/home/icon_arrow_gray.svg") center center/contain no-repeat;
      transform: rotate(180deg); }
    .sec-Course .slide-top .course-circle .upper {
      font-size: 1.61054vw;
      font-weight: bold;
      color: #fff;
      letter-spacing: 0.15em;
      padding-left: 0.15em;
      margin-top: 0.58565vw; }
    .sec-Course .slide-top .course-circle .lower {
      font-size: 0.95168vw;
      font-weight: bold;
      color: #fff;
      letter-spacing: 0.2em;
      padding-left: 0.2em;
      line-height: 1.7;
      margin-top: 0.58565vw; }
    @media screen and (min-width: 1366px) {
      .sec-Course .slide-top .course-circle {
        width: 170px;
        height: 170px; }
        .sec-Course .slide-top .course-circle::before {
          width: 45px;
          height: 10px;
          top: 21px; }
        .sec-Course .slide-top .course-circle::after {
          width: 45px;
          height: 10px;
          bottom: 16px; }
        .sec-Course .slide-top .course-circle .upper {
          font-size: 22px;
          margin-top: 8px; }
        .sec-Course .slide-top .course-circle .lower {
          font-size: 13px;
          margin-top: 8px; } }
    @media screen and (max-width: 499px) {
      .sec-Course .slide-top .course-circle {
        display: none; } }

@media screen and (max-width: 499px) {
  .sec-Course .slide-wrap .Container {
    flex-flow: column; } }

.sec-Course .slide-wrap .image-area, .sec-Course .slide-wrap .text-area {
  width: 50vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

@media screen and (max-width: 499px) {
  .sec-Course .slide-wrap .image-area {
    width: 100vw;
    height: 42%; }
  .sec-Course .slide-wrap .text-area {
    align-items: stretch;
    width: 100vw;
    height: 58%; } }

.sec-Course .slide-wrap .band-tips-pc {
  color: #fff;
  font-size: 0.87848vw;
  letter-spacing: 0.1em;
  padding: 0.58565vw;
  box-sizing: border-box;
  width: 30.01464vw;
  background: #E6140A;
  position: relative;
  background: linear-gradient(-45deg, transparent 0.51245vw, #E6140A 0);
  /*&::after {
                content: '';
                position: absolute;
                right: 0;
                bottom: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 0 _vw(10, 1366) _vw(10, 1366);
                border-color: transparent transparent #000 transparent;
                @include _mq-up(xxl) {
                    border-width: 0 0 10px 10px;
                }
                @include _mq-down(sm) {
                    display: none;
                }
            }*/ }
  @media screen and (min-width: 1366px) {
    .sec-Course .slide-wrap .band-tips-pc {
      font-size: 12px;
      padding: 8px;
      width: 410px;
      background: linear-gradient(-45deg, transparent 7px, #E6140A 0); } }
  @media screen and (max-width: 499px) {
    .sec-Course .slide-wrap .band-tips-pc {
      display: none; } }

.sec-Course .slide-wrap .band-tips-sp {
  color: #fff;
  background: #E6140A; }
  @media screen and (min-width: 500px) {
    .sec-Course .slide-wrap .band-tips-sp {
      display: none; } }
  @media screen and (max-width: 499px) {
    .sec-Course .slide-wrap .band-tips-sp {
      font-size: 2.13333vw;
      width: 26.66667vw;
      height: 26.66667vw;
      border-radius: 50%;
      padding: 2px 0 0 0;
      line-height: 1.7;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      position: absolute;
      top: 20.8vw;
      right: 5.06667vw; }
      .sec-Course .slide-wrap .band-tips-sp span.large {
        display: block;
        font-size: 3.41333vw;
        font-weight: bold;
        margin-bottom: 2px;
        letter-spacing: 0.15em; }
      .sec-Course .slide-wrap .band-tips-sp span.mini {
        display: inline-block;
        letter-spacing: 0.2em; } }

.sec-Course .slide-wrap .course-tips {
  display: block;
  color: #fff;
  font-size: 0.91508vw;
  font-weight: normal;
  width: 15.00732vw;
  padding: 1.02489vw 0;
  box-sizing: border-box;
  background: #5f6469;
  letter-spacing: 0.15em; }
  @media screen and (min-width: 1366px) {
    .sec-Course .slide-wrap .course-tips {
      font-size: 12.5px;
      width: 205px;
      padding: 14px 0; } }
  @media screen and (max-width: 499px) {
    .sec-Course .slide-wrap .course-tips {
      font-size: 3.2vw;
      font-weight: bold;
      width: 66.66667vw;
      padding: 1.86667vw 0;
      letter-spacing: 0.2em;
      margin-top: 4vw; } }

.sec-Course .slide-wrap .contents__box {
  position: relative;
  width: 30.01464vw;
  height: 35.13909vw; }
  @media screen and (min-width: 1366px) {
    .sec-Course .slide-wrap .contents__box {
      width: 410px;
      height: 480px; } }
  @media screen and (max-width: 499px) {
    .sec-Course .slide-wrap .contents__box {
      width: 80vw;
      height: auto;
      padding: 9.74513vh 0; } }
  .sec-Course .slide-wrap .contents__box h3 {
    font-size: 2.70864vw;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 0.2em;
    margin-top: 3.95315vw;
    margin-bottom: 3.07467vw;
    color: #fff; }
    @media screen and (min-width: 1366px) {
      .sec-Course .slide-wrap .contents__box h3 {
        font-size: 37px;
        margin-top: 50px;
        margin-bottom: 42px; } }
    @media screen and (max-width: 499px) {
      .sec-Course .slide-wrap .contents__box h3 {
        flex-flow: column;
        font-size: 7.2vw;
        margin-top: 0;
        margin-bottom: 5.33333vw; } }
  .sec-Course .slide-wrap .contents__box p {
    font-size: 1.0981vw;
    text-align: justify;
    color: #fff;
    line-height: 2.3333;
    letter-spacing: 0.1em; }
    @media screen and (min-width: 1366px) {
      .sec-Course .slide-wrap .contents__box p {
        font-size: 15px; } }
    @media screen and (max-width: 499px) {
      .sec-Course .slide-wrap .contents__box p {
        font-size: 3.2vw;
        line-height: 1.8333; } }

@media screen and (min-width: 500px) {
  .sec-Course .Advance {
    background: url("../../assets/img/home/bg_course_hb-ad_pc.jpg") center center/cover no-repeat; } }

@media screen and (max-width: 499px) {
  .sec-Course .Advance {
    background: url("../../assets/img/home/bg_course_hb-ad_sp.jpg") center center/cover no-repeat; } }

@media screen and (min-width: 500px) {
  .sec-Course .Advance .Container {
    flex-direction: row-reverse; } }

@media screen and (max-width: 499px) {
  .sec-Course .Advance .Container .contents__box {
    height: 44.97751vw;
    /*.btn-center {
                        position: static;
                    }*/ }
    .sec-Course .Advance .Container .contents__box p {
      text-align: center;
      letter-spacing: 0.075em; } }

.sec-Course .Advance .Container .Inr h4 {
  position: absolute;
  width: 42.0205vw;
  height: 27.2328vw;
  left: -7.68668vw;
  top: -6.22255vw; }
  @media screen and (min-width: 1366px) {
    .sec-Course .Advance .Container .Inr h4 {
      width: 574px;
      height: 372px;
      left: -105px;
      top: -85px; } }
  @media screen and (max-width: 499px) {
    .sec-Course .Advance .Container .Inr h4 {
      width: 76.8vw;
      height: 27.43628vh;
      left: -51.2vw;
      top: -25.78711vh; } }

.sec-Course .Advance .Container .text-area {
  background: #7fa1cd;
  position: relative; }
  .sec-Course .Advance .Container .text-area::after {
    content: '';
    background: #7fa1cd;
    width: 50vw;
    height: 100%;
    position: absolute;
    right: -50vw;
    top: 0;
    transition: 1s; }
  @media screen and (max-width: 499px) {
    .sec-Course .Advance .Container .text-area::after {
      width: 0;
      right: 0; } }

.sec-Course .Advance.swiper-slide-active .Container .text-area::after {
  width: 0;
  right: 0; }

@media screen and (min-width: 500px) {
  .sec-Course .Working {
    background: url("../../assets/img/home/bg_course_hb-ws_pc.jpg") center center/cover no-repeat; } }

@media screen and (max-width: 499px) {
  .sec-Course .Working {
    background: url("../../assets/img/home/bg_course_hb-ws_sp.jpg") center top/cover no-repeat; } }

@media screen and (max-width: 499px) {
  .sec-Course .Working .Container .contents__box {
    height: 44.97751vw; }
    .sec-Course .Working .Container .contents__box p {
      text-align: center; } }

.sec-Course .Working .Container .Inr h4 {
  position: absolute;
  width: 42.0205vw;
  height: 27.2328vw;
  left: -31.62518vw;
  top: -6.22255vw; }
  @media screen and (min-width: 1366px) {
    .sec-Course .Working .Container .Inr h4 {
      width: 574px;
      height: 372px;
      left: -432px;
      top: -85px; } }
  @media screen and (max-width: 499px) {
    .sec-Course .Working .Container .Inr h4 {
      width: 76.8vw;
      height: 27.43628vh;
      left: -51.2vw;
      top: -25.78711vh; } }

.sec-Course .Working .Container .text-area {
  background: #7fa1cd;
  position: relative; }
  .sec-Course .Working .Container .text-area::after {
    content: '';
    background: #7fa1cd;
    width: 50vw;
    height: 100%;
    position: absolute;
    left: -50vw;
    top: 0;
    transition: 1s; }
  @media screen and (max-width: 499px) {
    .sec-Course .Working .Container .text-area::after {
      width: 0;
      left: 0; } }

.sec-Course .Working.swiper-slide-active .Container .text-area::after {
  width: 0;
  left: 0; }

@media screen and (min-width: 500px) {
  .sec-Course .Beauty {
    background: url("../../assets/img/home/bg_course_bm_pc.jpg") center center/cover no-repeat; } }

@media screen and (max-width: 499px) {
  .sec-Course .Beauty {
    background: url("../../assets/img/home/bg_course_bm_sp.jpg") center top/contain no-repeat; } }

@media screen and (min-width: 500px) {
  .sec-Course .Beauty .Container {
    flex-direction: row-reverse; } }

.sec-Course .Beauty .Container .contents__box h3 {
  display: block;
  letter-spacing: 0em;
  text-align: justify;
  text-align-last: justify;
  text-justify: inter-ideograph;
  /*@include _mq-down(sm) {
                        text-align: center;
                        text-align-last: center;
                    }*/ }
  @media screen and (max-width: 499px) {
    .sec-Course .Beauty .Container .contents__box h3 {
      letter-spacing: 0.1em;
      margin-top: 1.86667vw;
      text-align: center;
      text-align-last: center; } }

.sec-Course .Beauty .Container .contents__box p {
  text-align: justify;
  text-align-last: center; }

@media screen and (max-width: 499px) {
  .sec-Course .Beauty .Container .contents__box {
    height: 25.33733vh; } }

.sec-Course .Beauty .Container .Inr h4 {
  position: absolute;
  width: 50.58565vw;
  height: 26.86676vw;
  left: -7.9063vw;
  top: -2.48902vw; }
  @media screen and (min-width: 1366px) {
    .sec-Course .Beauty .Container .Inr h4 {
      width: 691px;
      height: 367px;
      left: -108px;
      top: -34px; } }
  @media screen and (max-width: 499px) {
    .sec-Course .Beauty .Container .Inr h4 {
      width: 92.53333vw;
      height: 48.53333vw;
      left: -50.93333vw;
      top: -21.28936vh; } }

.sec-Course .Beauty .Container .text-area {
  background: #ea81a2;
  position: relative; }
  .sec-Course .Beauty .Container .text-area::after {
    content: '';
    background: #ea81a2;
    width: 50vw;
    height: 100%;
    position: absolute;
    right: -50vw;
    top: 0;
    transition: 1s; }
  @media screen and (max-width: 499px) {
    .sec-Course .Beauty .Container .text-area::after {
      width: 0;
      right: 0; } }

.sec-Course .Beauty.swiper-slide-active .Container .text-area::after {
  width: 0;
  right: 0; }

.sec-Support {
  text-align: center; }
  @media screen and (min-width: 500px) {
    .sec-Support {
      background: url("../../assets/img/home/bg_support_pc.jpg") center center/cover no-repeat; } }
  @media screen and (max-width: 499px) {
    .sec-Support {
      background: url("../../assets/img/home/bg_support_sp.jpg") center center/cover no-repeat; } }
  .sec-Support .tit {
    font-size: 3.66032vw;
    line-height: 1.4;
    font-weight: bold;
    letter-spacing: 0.15em; }
    @media screen and (min-width: 1366px) {
      .sec-Support .tit {
        font-size: 50px; } }
    @media screen and (max-width: 499px) {
      .sec-Support .tit {
        font-size: 6.66667vw;
        line-height: 1.65; } }
  .sec-Support .btn-box-support {
    margin-top: 3.66032vw; }
    @media screen and (min-width: 1366px) {
      .sec-Support .btn-box-support {
        margin-top: 50px; } }
    @media screen and (max-width: 499px) {
      .sec-Support .btn-box-support {
        margin-top: 6.66667vw; } }

@media screen and (min-width: 500px) {
  .sec-Facility {
    background: url("../../assets/img/home/bg_facility_pc.jpg") center center/cover no-repeat; } }

@media screen and (max-width: 499px) {
  .sec-Facility {
    background: #fff; }
    .sec-Facility .Inr {
      position: static;
      width: auto;
      height: auto; } }

.sec-Facility .contents__box {
  text-align: center;
  position: absolute;
  left: 1.75695vw;
  top: -18.1552vw; }
  @media screen and (max-width: 499px) {
    .sec-Facility .contents__box {
      position: static; } }

.sec-Facility .tit {
  font-size: 2.92826vw;
  font-weight: bold;
  letter-spacing: 0.15em;
  padding-left: 0.15em;
  position: relative;
  padding-top: 0.29283vw;
  padding-bottom: 1.31772vw;
  margin-bottom: 0.87848vw; }
  @media screen and (min-width: 1366px) {
    .sec-Facility .tit {
      font-size: 40px;
      padding-top: 4px;
      padding-bottom: 18px;
      margin-bottom: 12px; } }
  @media screen and (max-width: 499px) {
    .sec-Facility .tit {
      font-size: 6.66667vw;
      padding-top: 2.13333vw;
      padding-bottom: 2.66667vw;
      margin-bottom: 1.6vw; } }
  .sec-Facility .tit:before {
    content: '';
    background: url("../../assets/img/home/txt_facility.png") center center/contain no-repeat;
    width: 38.653vw;
    height: 10.39531vw;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); }
    @media screen and (min-width: 1366px) {
      .sec-Facility .tit:before {
        width: 528px;
        height: 142px; } }
    @media screen and (max-width: 499px) {
      .sec-Facility .tit:before {
        width: 101.86667vw;
        height: 27.2vw; } }
  .sec-Facility .tit::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 2.19619vw;
    height: 0.14641vw;
    margin: 0 auto;
    background: #E6140A; }
    @media screen and (min-width: 1366px) {
      .sec-Facility .tit::after {
        width: 30px;
        height: 2px; } }
    @media screen and (max-width: 499px) {
      .sec-Facility .tit::after {
        width: 5.33333vw;
        height: 1px; } }

.sec-Facility .text {
  font-size: 1.1713vw;
  line-height: 2;
  letter-spacing: 0.1em;
  padding-left: 0.1em; }
  @media screen and (min-width: 1366px) {
    .sec-Facility .text {
      font-size: 16px; } }
  @media screen and (max-width: 499px) {
    .sec-Facility .text {
      font-size: 3.2vw; } }

.sec-Facility .btn-box-facility {
  margin-top: 1.83016vw; }
  @media screen and (min-width: 1366px) {
    .sec-Facility .btn-box-facility {
      margin-top: 25px; } }
  @media screen and (max-width: 499px) {
    .sec-Facility .btn-box-facility {
      margin-top: 6.66667vw; } }

.sec-Facility .image-upper {
  width: 32.9429vw;
  margin-bottom: 3.3675vw; }
  @media screen and (min-width: 1366px) {
    .sec-Facility .image-upper {
      width: 450px;
      margin-bottom: 46px; } }
  @media screen and (max-width: 499px) {
    .sec-Facility .image-upper {
      width: 78.66667vw;
      margin-bottom: 19.2vw; } }

.sec-Facility .image-lower {
  width: 32.9429vw;
  margin-top: 3.66032vw; }
  @media screen and (min-width: 1366px) {
    .sec-Facility .image-lower {
      width: 450px;
      margin-top: 50px; } }
  @media screen and (max-width: 499px) {
    .sec-Facility .image-lower {
      width: 78.66667vw;
      margin-top: 16vw; } }

@media screen and (min-width: 500px) {
  .slide-Facility {
    background: url("../../assets/img/home/bg_facility_pc.jpg") center center/cover no-repeat; } }

@media screen and (max-width: 499px) {
  .slide-Facility {
    background: #fff; }
    .slide-Facility .Inr {
      position: static;
      width: auto;
      height: auto; } }

.slide-Facility .f-contents__box {
  text-align: center;
  position: absolute;
  left: 1.75695vw;
  top: -18.1552vw; }
  @media screen and (max-width: 499px) {
    .slide-Facility .f-contents__box {
      position: static; } }

.slide-Facility .tit {
  font-size: 2.92826vw;
  font-weight: bold;
  letter-spacing: 0.15em;
  padding-left: 0.15em;
  position: relative;
  padding-top: 0.29283vw;
  padding-bottom: 1.31772vw;
  margin-bottom: 0.87848vw; }
  @media screen and (min-width: 1366px) {
    .slide-Facility .tit {
      font-size: 40px;
      padding-top: 4px;
      padding-bottom: 18px;
      margin-bottom: 12px; } }
  @media screen and (max-width: 499px) {
    .slide-Facility .tit {
      font-size: 6.66667vw;
      padding-top: 2.13333vw;
      padding-bottom: 2.66667vw;
      margin-bottom: 1.6vw; } }
  .slide-Facility .tit:before {
    content: '';
    background: url("../../assets/img/home/txt_facility.png") center center/contain no-repeat;
    width: 38.653vw;
    height: 10.39531vw;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); }
    @media screen and (min-width: 1366px) {
      .slide-Facility .tit:before {
        width: 528px;
        height: 142px; } }
    @media screen and (max-width: 499px) {
      .slide-Facility .tit:before {
        width: 101.86667vw;
        height: 27.2vw; } }
  .slide-Facility .tit::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 2.19619vw;
    height: 0.14641vw;
    margin: 0 auto;
    background: #E6140A; }
    @media screen and (min-width: 1366px) {
      .slide-Facility .tit::after {
        width: 30px;
        height: 2px; } }
    @media screen and (max-width: 499px) {
      .slide-Facility .tit::after {
        width: 5.33333vw;
        height: 1px; } }

.slide-Facility .text {
  font-size: 1.1713vw;
  line-height: 2;
  letter-spacing: 0.1em;
  padding-left: 0.1em; }
  @media screen and (min-width: 1366px) {
    .slide-Facility .text {
      font-size: 16px; } }
  @media screen and (max-width: 499px) {
    .slide-Facility .text {
      font-size: 3.2vw; } }

.slide-Facility .btn-box-facility {
  margin-top: 1.83016vw; }
  @media screen and (min-width: 1366px) {
    .slide-Facility .btn-box-facility {
      margin-top: 25px; } }
  @media screen and (max-width: 499px) {
    .slide-Facility .btn-box-facility {
      margin-top: 6.66667vw; } }

.slide-Facility .image-upper {
  width: 32.9429vw;
  margin-bottom: 3.3675vw; }
  @media screen and (min-width: 1366px) {
    .slide-Facility .image-upper {
      width: 450px;
      margin-bottom: 46px; } }
  @media screen and (max-width: 499px) {
    .slide-Facility .image-upper {
      width: 78.66667vw;
      margin-bottom: 19.2vw; } }

.slide-Facility .image-lower {
  width: 32.9429vw;
  margin-top: 3.66032vw; }
  @media screen and (min-width: 1366px) {
    .slide-Facility .image-lower {
      width: 450px;
      margin-top: 50px; } }
  @media screen and (max-width: 499px) {
    .slide-Facility .image-lower {
      width: 78.66667vw;
      margin-top: 16vw; } }

@media screen and (min-width: 500px) {
  .sec-Opencampus {
    background: url("../../assets/img/home/bg_opencampus_pc.jpg") center center/cover no-repeat; } }

@media screen and (max-width: 499px) {
  .sec-Opencampus {
    background: url("../../assets/img/home/bg_opencampus_sp.jpg") center center/cover no-repeat; } }

@media screen and (max-width: 499px) {
  .sec-Opencampus .contents__box {
    padding-top: 60px; } }

.sec-Opencampus .contents__box .image {
  width: 71.74231vw;
  display: block; }
  @media screen and (min-width: 1366px) {
    .sec-Opencampus .contents__box .image {
      width: 980px; } }
  @media screen and (max-width: 499px) {
    .sec-Opencampus .contents__box .image {
      width: 91.73333vw;
      margin: 0 auto; } }

@media screen and (max-width: 499px) {
  .sec-Opencampus .contents__box p {
    font-size: 3.46667vw; } }

@media screen and (max-width: 499px) {
  .sec-Opencampus .contents__box .image-sp {
    width: 91.73333vw; } }

.sec-Opencampus .btn-box-oc {
  display: flex;
  justify-content: center;
  margin-top: 2.56223vw; }
  @media screen and (min-width: 1366px) {
    .sec-Opencampus .btn-box-oc {
      gap: 40px;
      margin-top: 35px; } }
  @media screen and (max-width: 499px) {
    .sec-Opencampus .btn-box-oc {
      flex-flow: column;
      align-items: center;
      margin-top: 5.33333vw;
      margin-bottom: 5.33333vw; } }

.sec-Opencampus .oc-btn {
  display: block;
  width: 18.30161vw;
  height: 4.39239vw;
  border-radius: 2.19619vw;
  color: #fff;
  text-align: center;
  font-size: 1.02489vw;
  font-weight: 600;
  padding: 1.68375vw; }
  @media screen and (max-width: 499px) {
    .sec-Opencampus .oc-btn {
      width: 56vw;
      height: 11.2vw;
      border-radius: 5.6vw;
      font-size: 3.2vw;
      padding: 4vw; } }
  .sec-Opencampus .oc-btn.oc {
    background: #E6140A; }
    @media screen and (min-width: 768px) {
      .sec-Opencampus .oc-btn.oc:hover {
        background: rgba(230, 20, 10, 0.7); } }
  .sec-Opencampus .oc-btn.choi {
    background: orange; }
    @media screen and (min-width: 768px) {
      .sec-Opencampus .oc-btn.choi {
        margin-left: 2.92826vw; }
        .sec-Opencampus .oc-btn.choi:hover {
          background: rgba(255, 165, 0, 0.7); } }
    @media screen and (max-width: 499px) {
      .sec-Opencampus .oc-btn.choi {
        margin-top: 10px; } }

@media screen and (min-width: 500px) {
  .sec-HighSchool {
    background: url("../../assets/img/home/bg_highschool_pc.jpg") center center/cover no-repeat; } }

@media screen and (max-width: 499px) {
  .sec-HighSchool {
    background: url("../../assets/img/home/bg_highschool_sp.jpg") center center/cover no-repeat;
    background-color: #fff; } }

.sec-HighSchool .Container .contents__box {
  width: 36.60322vw;
  height: 21.96193vw;
  background: #E6140A;
  padding: 2.56223vw 2.92826vw 2.92826vw; }
  .sec-HighSchool .Container .contents__box::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 2.19619vw 2.19619vw;
    border-color: transparent transparent rgba(0, 0, 0, 0.2) transparent;
    position: absolute;
    right: 1.0981vw;
    bottom: 1.0981vw; }
  @media screen and (min-width: 1366px) {
    .sec-HighSchool .Container .contents__box {
      width: 500px;
      height: 300px;
      padding: 35px 40px 40px; }
      .sec-HighSchool .Container .contents__box::after {
        border-width: 0 0 30px 30px;
        right: 15px;
        bottom: 15px; } }
  @media screen and (max-width: 499px) {
    .sec-HighSchool .Container .contents__box {
      width: 100vw;
      height: 38.08096vh;
      padding: 0 0 3.74813vh 0;
      display: flex;
      flex-flow: column;
      justify-content: center;
      position: absolute;
      bottom: 0; }
      .sec-HighSchool .Container .contents__box::after {
        border-width: 0 0 8vw 8vw;
        right: 4vw;
        bottom: 4vw; } }
  .sec-HighSchool .Container .contents__box h3 {
    font-size: 2.34261vw;
    font-weight: 700;
    text-align: center;
    color: #fff;
    line-height: 1.4286;
    letter-spacing: 0.15em; }
    @media screen and (min-width: 1366px) {
      .sec-HighSchool .Container .contents__box h3 {
        font-size: 32px; } }
    @media screen and (max-width: 499px) {
      .sec-HighSchool .Container .contents__box h3 {
        font-size: 6.93333vw;
        line-height: 1.3846; } }
  .sec-HighSchool .Container .contents__box p {
    color: #fff;
    font-size: 1.0981vw;
    text-align: center;
    line-height: 2;
    margin-top: 1.46413vw;
    letter-spacing: 0.2em; }
    @media screen and (min-width: 1366px) {
      .sec-HighSchool .Container .contents__box p {
        font-size: 15px;
        margin-top: 20px; } }
    @media screen and (max-width: 499px) {
      .sec-HighSchool .Container .contents__box p {
        font-size: 3.2vw;
        margin-top: 2.66667vw;
        letter-spacing: 0.2em; } }

@media screen and (min-width: 500px) {
  .sec-BisenTube {
    background: url("../../assets/img/home/bg_bisentube_pc.jpg") center center/cover no-repeat; } }

@media screen and (max-width: 499px) {
  .sec-BisenTube {
    background: url("../../assets/img/home/bg_bisentube_sp.jpg") center center/cover no-repeat;
    background-color: #fff; } }

.sec-BisenTube .contents__box {
  text-align: center; }
  @media screen and (max-width: 499px) {
    .sec-BisenTube .contents__box {
      margin-top: 29.33333vw; } }
  .sec-BisenTube .contents__box .tit {
    font-size: 2.19619vw;
    font-weight: bold;
    letter-spacing: 0.15em;
    position: relative;
    padding-bottom: 1.02489vw;
    margin-bottom: 0.87848vw;
    line-height: 1.5333; }
    .sec-BisenTube .contents__box .tit::after {
      content: '';
      width: 2.19619vw;
      height: 0.14641vw;
      background: #E6140A;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto; }
    @media screen and (min-width: 1366px) {
      .sec-BisenTube .contents__box .tit {
        font-size: 30px;
        padding-bottom: 14px;
        margin-bottom: 12px; }
        .sec-BisenTube .contents__box .tit::after {
          width: 30px;
          height: 2px; } }
    @media screen and (max-width: 499px) {
      .sec-BisenTube .contents__box .tit {
        font-size: 5.33333vw;
        padding-bottom: 2.66667vw;
        margin-bottom: 2.13333vw;
        margin-right: 6.66667vw; }
        .sec-BisenTube .contents__box .tit::after {
          width: 5.33333vw;
          height: 1px; } }
  .sec-BisenTube .contents__box .text {
    font-size: 1.1713vw;
    line-height: 2;
    letter-spacing: 0.1em; }
    @media screen and (min-width: 1366px) {
      .sec-BisenTube .contents__box .text {
        font-size: 16px; } }
    @media screen and (max-width: 499px) {
      .sec-BisenTube .contents__box .text {
        font-size: 3.2vw;
        margin-right: 6.66667vw; } }
  .sec-BisenTube .contents__box .btn-box-bisentube {
    margin-top: 1.83016vw; }
    @media screen and (min-width: 1366px) {
      .sec-BisenTube .contents__box .btn-box-bisentube {
        margin-top: 25px; } }
    @media screen and (max-width: 499px) {
      .sec-BisenTube .contents__box .btn-box-bisentube {
        margin-top: 53.33333vw; } }

@media screen and (min-width: 500px) {
  .sec-Admission {
    background-image: url("../../assets/img/home/bg_admission_pc.png");
    background-size: 10px;
    background-color: #fff; } }

@media screen and (max-width: 499px) {
  .sec-Admission {
    background: url("../../assets/img/home/bg_admission_pc.png");
    background-size: 10px;
    background-color: #fff; } }

.sec-Admission .top-wrap .contents__box {
  width: 58.56515vw;
  height: 29.94143vw;
  background: rgba(255, 255, 255, 0.8);
  position: relative; }
  @media screen and (min-width: 1366px) {
    .sec-Admission .top-wrap .contents__box {
      width: 800px;
      height: 409px; } }
  @media screen and (max-width: 499px) {
    .sec-Admission .top-wrap .contents__box {
      width: 77.33333vw;
      height: 106.66667vw; } }
  .sec-Admission .top-wrap .contents__box .title {
    position: absolute;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    color: #E6140A;
    font-size: 1.97657vw;
    font-weight: 500;
    letter-spacing: 0.07em; }
    @media screen and (min-width: 1366px) {
      .sec-Admission .top-wrap .contents__box .title {
        font-size: 27px; } }
    @media screen and (max-width: 499px) {
      .sec-Admission .top-wrap .contents__box .title {
        font-size: 5.06667vw; } }
  .sec-Admission .top-wrap .contents__box .bisen .jp {
    font-size: 0.73206vw;
    letter-spacing: 1em; }
    @media screen and (min-width: 1366px) {
      .sec-Admission .top-wrap .contents__box .bisen .jp {
        font-size: 10px; } }
    @media screen and (max-width: 499px) {
      .sec-Admission .top-wrap .contents__box .bisen .jp {
        font-size: 2.66667vw; } }
  .sec-Admission .top-wrap .contents__box .bisen .en {
    font-size: 3.29429vw;
    color: #E6140A;
    font-weight: 500;
    margin-top: 0.87848vw; }
    @media screen and (min-width: 1366px) {
      .sec-Admission .top-wrap .contents__box .bisen .en {
        font-size: 45px;
        margin-top: 12px; } }
    @media screen and (max-width: 499px) {
      .sec-Admission .top-wrap .contents__box .bisen .en {
        font-size: 8vw;
        line-height: 10.66667vw;
        margin-top: 1.86667vw; } }
  .sec-Admission .top-wrap .contents__box h2 {
    font-size: 2.19619vw;
    font-weight: bold;
    letter-spacing: 0.15em;
    position: relative;
    margin-top: 0.95168vw;
    padding-bottom: 1.61054vw;
    margin-bottom: 1.1713vw; }
    .sec-Admission .top-wrap .contents__box h2::after {
      content: '';
      width: 2.19619vw;
      height: 0.14641vw;
      background: #E6140A;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto; }
    @media screen and (min-width: 1366px) {
      .sec-Admission .top-wrap .contents__box h2 {
        font-size: 30px;
        margin-top: 13px;
        padding-bottom: 22px;
        margin-bottom: 16px; } }
    @media screen and (max-width: 499px) {
      .sec-Admission .top-wrap .contents__box h2 {
        font-size: 6.13333vw;
        margin-top: 1.86667vw;
        padding-bottom: 5.86667vw;
        margin-bottom: 4.26667vw; }
        .sec-Admission .top-wrap .contents__box h2::after {
          width: 8vw;
          height: 0.53333vw; } }
  .sec-Admission .top-wrap .contents__box .text {
    font-size: 1.1713vw;
    line-height: 2;
    letter-spacing: 0.1em; }
    @media screen and (min-width: 1366px) {
      .sec-Admission .top-wrap .contents__box .text {
        font-size: 16px; } }
    @media screen and (max-width: 499px) {
      .sec-Admission .top-wrap .contents__box .text {
        font-size: 3.46667vw; } }

@media screen and (max-width: 499px) {
  .sec-Admission .slide-wrap .Container {
    padding-top: 8vw; } }

.sec-Admission .slide-wrap .contents__box {
  width: 58.56515vw;
  height: 29.94143vw;
  position: relative;
  box-sizing: border-box;
  position: relative; }
  @media screen and (min-width: 1366px) {
    .sec-Admission .slide-wrap .contents__box {
      width: 800px;
      height: 409px; } }
  @media screen and (max-width: 499px) {
    .sec-Admission .slide-wrap .contents__box {
      width: 77.33333vw;
      height: 106.66667vw; } }
  .sec-Admission .slide-wrap .contents__box--upper {
    background: rgba(255, 255, 255, 0.8);
    height: 2.19619vw;
    width: 56.36896vw; }
    @media screen and (min-width: 1366px) {
      .sec-Admission .slide-wrap .contents__box--upper {
        height: 30px;
        width: 770px; } }
    @media screen and (max-width: 499px) {
      .sec-Admission .slide-wrap .contents__box--upper {
        height: 6.66667vw;
        width: 70.66667vw; } }
  .sec-Admission .slide-wrap .contents__box--inner {
    background: rgba(255, 255, 255, 0.8);
    height: 27.74524vw;
    padding-top: 0.36603vw;
    box-shadow: 0.73206vw 0.73206vw 0.73206vw 0 rgba(0, 0, 0, 0.15); }
    @media screen and (min-width: 1366px) {
      .sec-Admission .slide-wrap .contents__box--inner {
        height: 379px;
        padding-top: 5px;
        box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.15); } }
    @media screen and (max-width: 499px) {
      .sec-Admission .slide-wrap .contents__box--inner {
        height: 100vw;
        padding-top: 1.33333vw;
        box-shadow: 1.86667vw 1.86667vw 1.86667vw 0 rgba(0, 0, 0, 0.15); } }
  .sec-Admission .slide-wrap .contents__box::before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 2.19619vw 0 0 2.19619vw;
    border-color: transparent transparent transparent #b2b2b2;
    position: absolute;
    top: 0;
    right: 0; }
    @media screen and (min-width: 1366px) {
      .sec-Admission .slide-wrap .contents__box::before {
        border-width: 30px 0 0 30px; } }
    @media screen and (max-width: 499px) {
      .sec-Admission .slide-wrap .contents__box::before {
        border-width: 6.66667vw 0 0 6.66667vw; } }
  .sec-Admission .slide-wrap .contents__box::after {
    content: '';
    width: 58.56515vw;
    height: 2.48902vw;
    background: url("../../assets/img/home/txt_adm_information.svg") center center/contain no-repeat;
    position: absolute;
    top: -3.66032vw;
    left: 0; }
    @media screen and (min-width: 1366px) {
      .sec-Admission .slide-wrap .contents__box::after {
        width: 800px;
        height: 34px;
        top: -50px; } }
    @media screen and (max-width: 499px) {
      .sec-Admission .slide-wrap .contents__box::after {
        width: 76vw;
        height: 3.2vw;
        top: -6.66667vw; } }
  .sec-Admission .slide-wrap .contents__box .icon {
    width: 6.58858vw;
    margin: 0 auto; }
    @media screen and (min-width: 1366px) {
      .sec-Admission .slide-wrap .contents__box .icon {
        width: 90px; } }
    @media screen and (max-width: 499px) {
      .sec-Admission .slide-wrap .contents__box .icon {
        width: 16vw; } }
  .sec-Admission .slide-wrap .contents__box .subhead {
    color: #E6140A;
    font-size: 1.83016vw;
    line-height: 1.6;
    font-weight: bold;
    letter-spacing: 0.1em;
    text-align: center;
    margin-top: 0.73206vw; }
    @media screen and (min-width: 1366px) {
      .sec-Admission .slide-wrap .contents__box .subhead {
        font-size: 25px;
        margin-top: 10px; } }
    @media screen and (max-width: 499px) {
      .sec-Admission .slide-wrap .contents__box .subhead {
        font-size: 6.13333vw;
        margin-top: 4vw; } }
  .sec-Admission .slide-wrap .contents__box .list {
    width: 24.89019vw;
    margin: 1.0981vw auto 0;
    color: #E6140A; }
    @media screen and (min-width: 1366px) {
      .sec-Admission .slide-wrap .contents__box .list {
        width: 340px;
        margin: 15px auto 0; } }
    @media screen and (max-width: 499px) {
      .sec-Admission .slide-wrap .contents__box .list {
        width: 61.33333vw;
        margin-top: 2.66667vw; } }
    .sec-Admission .slide-wrap .contents__box .list li {
      border-bottom: 1px solid #dbdbdb;
      font-size: 1.1713vw;
      text-align: left;
      letter-spacing: 0.1em;
      line-height: 1;
      padding-top: 1.24451vw;
      padding-bottom: 1.24451vw;
      padding-left: 1.2em;
      /*@include _mq-down(sm) {
                        height: _vw(50, 375);
                        font-size: _vw(15, 375);
                        padding-top: _vw(17, 375);
                        padding-bottom: _vw(17, 375);
                    }*/ }
      @media screen and (min-width: 1366px) {
        .sec-Admission .slide-wrap .contents__box .list li {
          font-size: 16px;
          padding-top: 17px;
          padding-bottom: 17px; } }
      @media screen and (max-width: 499px) {
        .sec-Admission .slide-wrap .contents__box .list li {
          height: auto;
          font-size: 3.46667vw;
          padding-top: 4.8vw;
          padding-bottom: 4.8vw; } }

.sec-Admission .slide-wrap.slide07 .contents__box .subhead {
  margin-top: 2.56223vw; }
  @media screen and (min-width: 1366px) {
    .sec-Admission .slide-wrap.slide07 .contents__box .subhead {
      margin-top: 35px; } }
  @media screen and (max-width: 499px) {
    .sec-Admission .slide-wrap.slide07 .contents__box .subhead {
      line-height: 1.4783;
      padding-left: 12px; } }

.sec-Admission .slide-wrap.slide07 .contents__box .list {
  margin-top: 2.92826vw; }
  @media screen and (min-width: 1366px) {
    .sec-Admission .slide-wrap.slide07 .contents__box .list {
      margin-top: 40px; } }
  .sec-Admission .slide-wrap.slide07 .contents__box .list li {
    padding-left: 0.1em; }

.topics-box {
  width: 27.81845vw;
  position: fixed;
  top: 20%;
  left: -24.89019vw;
  background-color: #fff;
  padding: 2.04978vw 3.51391vw 2.04978vw 2.04978vw;
  border-radius: 0.73206vw;
  border: 1px solid #E6140A;
  overflow: hidden;
  z-index: 20;
  transition: all .3s ease; }
  @media screen and (min-width: 1366px) {
    .topics-box {
      width: 380px;
      left: -340px;
      border-radius: 10px;
      padding: 28px 48px 18px 28px; } }
  @media screen and (max-width: 499px) {
    .topics-box {
      width: 250px;
      top: -226px;
      left: -10px;
      border-radius: 10px;
      padding: 30px 18px 35px 30px; } }
  .topics-box .head {
    margin-bottom: 0.36603vw; }
    @media screen and (min-width: 1366px) {
      .topics-box .head {
        margin-bottom: 5px; } }
    @media screen and (max-width: 499px) {
      .topics-box .head {
        margin-bottom: 5px; } }
    .topics-box .head h4 {
      color: #E6140A;
      font-size: 1.83016vw;
      font-weight: bold;
      letter-spacing: 0.1em; }
      @media screen and (min-width: 1366px) {
        .topics-box .head h4 {
          font-size: 25px; } }
      @media screen and (max-width: 499px) {
        .topics-box .head h4 {
          font-size: 20px; }
          .topics-box .head h4 img {
            width: 100%; } }
  .topics-box .list {
    padding: 0.51245vw 0 0.65886vw;
    border-bottom: 1px solid #DCDCDC;
    line-height: 1.4; }
    .topics-box .list:last-child {
      border-bottom: 0; }
    @media screen and (min-width: 1366px) {
      .topics-box .list {
        padding: 7px 0 9px; } }
    @media screen and (max-width: 499px) {
      .topics-box .list {
        padding: 9px 0; } }
    .topics-box .list time, .topics-box .list p, .topics-box .list a {
      display: block;
      font-size: 1.02489vw; }
      @media screen and (min-width: 1366px) {
        .topics-box .list time, .topics-box .list p, .topics-box .list a {
          font-size: 14px; } }
      @media screen and (max-width: 499px) {
        .topics-box .list time, .topics-box .list p, .topics-box .list a {
          font-size: 13px; } }
    .topics-box .list p {
      color: #E6140A;
      height: auto;
      max-height: 2.92826vw;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; }
      @media screen and (min-width: 1366px) {
        .topics-box .list p {
          max-height: 50px; } }
      @media screen and (max-width: 499px) {
        .topics-box .list p {
          font-size: 14px;
          max-height: 40px; } }
  .topics-box .topics-box-close {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
    width: 2.92826vw;
    background-color: #E6140A;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    writing-mode: vertical-rl;
    font-size: 1.31772vw;
    font-weight: 600;
    color: #fff;
    letter-spacing: 0.15em;
    margin: 0; }
    @media screen and (min-width: 1366px) {
      .topics-box .topics-box-close {
        width: 40px;
        font-size: 18px; } }
    @media screen and (max-width: 499px) {
      .topics-box .topics-box-close {
        width: 40px;
        font-size: 15px;
        writing-mode: horizontal-tb; } }
    .topics-box .topics-box-close .topics-btn {
      position: relative;
      padding-bottom: 0.87848vw; }
      .topics-box .topics-box-close .topics-btn::after {
        content: '';
        position: absolute;
        right: 0.14641vw;
        left: 0;
        margin: 0 auto;
        bottom: -0.87848vw;
        width: 0.43924vw;
        height: 0.87848vw; }
      .topics-box .topics-box-close .topics-btn.close {
        display: none; }
        .topics-box .topics-box-close .topics-btn.close::after {
          background: url("../../assets/img/common/arrow-l-white.svg") no-repeat; }
      .topics-box .topics-box-close .topics-btn.open::after {
        background: url("../../assets/img/common/arrow-r-white.svg") no-repeat; }
      @media screen and (min-width: 1366px) {
        .topics-box .topics-box-close .topics-btn {
          padding-bottom: 12px; }
          .topics-box .topics-box-close .topics-btn::after {
            right: 2px;
            bottom: -12px;
            width: 6px;
            height: 12px; } }
      @media screen and (max-width: 499px) {
        .topics-box .topics-box-close .topics-btn {
          padding-bottom: 0;
          padding-right: 20px; }
          .topics-box .topics-box-close .topics-btn::after {
            right: 0;
            left: auto;
            bottom: 0;
            top: 2px;
            margin: auto 0;
            width: 12px;
            height: 6px; }
          .topics-box .topics-box-close .topics-btn.close {
            display: none; }
            .topics-box .topics-box-close .topics-btn.close::after {
              background: url("../../assets/img/common/arrow-t-white.svg") no-repeat; }
          .topics-box .topics-box-close .topics-btn.open::after {
            background: url("../../assets/img/common/arrow-b-white.svg") no-repeat; } }
    @media screen and (max-width: 499px) {
      .topics-box .topics-box-close {
        width: 100%;
        height: 30px;
        top: auto; } }
  .topics-box.active {
    left: -0.73206vw; }
    @media screen and (min-width: 1366px) {
      .topics-box.active {
        left: -10px; } }
    @media screen and (max-width: 499px) {
      .topics-box.active {
        left: -10px;
        top: -10px;
        margin-top: 60px; } }
    .topics-box.active .topics-box-close .open {
      display: none; }
    .topics-box.active .topics-box-close .close {
      display: block; }
