// Object - page - top

// ============================================
// mainvisual
// ============================================

.Inr {
    width: 0;
    height: 0;
    position: absolute;
}

.Container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.Container-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    height: 100%;
}

body:not(.fp-viewing-0):not(.fp-viewing-1) .mainvisual {
    display: none;
}

section.sec-Feature,
section.sec-Course,
section.sec-Support,
section.sec-Facility,
section.sec-Opencampus,
section.sec-HighSchool,
section.sec-BisenTube,
//section.sec-Admission,
section.sec-Footer {
    background-color: #fff;
}

.swiper-slide {
    min-height: auto; //追記必要？
}

.mainvisual {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    //height: calc(var(--vh, 1vh) * 100);
    background: url("../../assets/img/home/bg_mainvisual_pc.jpg") center top / _vw(1366) _vw(768) no-repeat;
    position: fixed;
    //z-index: 1;
    z-index: -1;
    @include _mq-down(md) {
        height: calc(var(--vh, 1vh) * 100);
    }
    @include _mq-down(sm) {
        background: url("../../assets/img/home/bg_mainvisual_sp.jpg") center center / cover no-repeat;
    }
    .Container {
        .text-01 {
            width: _vw(618, 1366);
            max-width: 618px;
            position: absolute;
            left: _vw(-600, 1366);
            top: _vh(-250, 768);
            @include _mq-up(xxl) {
                left: -600px;
            }
            @include _mq-down(sm) {
                width: _vw(150, 375);
                left: _vw(-160, 375);
                top: _vh(-220, 667);
            }
        }
        .text-02 {
            width: _vw(400, 1366);
            max-width: 400px;
            position: absolute;
            left: _vw(-600, 1366);
            top: _vw(-100, 1366);
            @include _mq-up(xxl) {
                width: 400px;
                left: -600px;
                top: -100px;
            }
            @include _mq-down(sm) {
                width: _vw(230, 375);
                left: _vw(-160, 375);
                top: _vh(65, 667);
            }
        }
    }
}

.sec-Feature {
    .slide-top {
        @include _mq-up(sm) {
            background: url("../../assets/img/home/bg_feature_pc.jpg") center center / cover no-repeat;
        }
        @include _mq-down(sm) {
            background: url("../../assets/img/home/bg_feature_sp.jpg") center center / cover no-repeat;
        }
        .Container {
            .inr {
                .text-future {
                    display: block;
                    width: _vw(410);
                    margin: 0 auto;
                    @include _mq-up(xxl) {
                        width: 410px;
                    }
                    @include _mq-down(sm) {
                        width: _vw(242, 375);
                    }
                }
            }
            .text {
                width: _vw(420, 1366);
                font-size: _vw(14, 1366);
                line-height: (34 / 14);
                margin-top: _vw(20);
                letter-spacing: 0.1em;
                @include _mq-up(xxl) {
                    width: 420px;
                    font-size: 14px;
                    margin-top: 20px;
                }
                @include _mq-down(sm) {
                    margin-top: 18px;
                    font-size: _vw(12, 375);
                    line-height: (27 / 12);
                    width: _vw(315, 375);
                }
            }
        }
    }
    .swiper {

    }
    .slide02 {
        @include _mq-up(sm) {
            background: url("../../assets/img/home/bg_feature-slide_pc.jpg") center center / cover no-repeat;
        }
        @include _mq-down(sm) {
            background: url("../../assets/img/home/bg_feature-slide_sp.jpg") right center / cover no-repeat;
        }
    }
    .slide03, .slide04 {
        background: url("../../assets/img/home/bg_feature-slide-tips.png") center / 10px 10px repeat;
    }
    .slide-wrap {
        .Container {
            //padding-top: _vw(60, 1366); //調整
            @include _mq-down(sm) {
                //padding-top: _vw(60, 375);
            }
        }
        .contents__box {
            width: _vw(800, 1366);
            height: _vw(350, 1366);
            background: rgba(255,255,255,0.8);
            position: relative;
            box-sizing: border-box;
            margin-top: _vw(60);
            padding-top: _vw(55);
            @include _mq-up(xxl) {
                width: 800px;
                height: 350px;
                padding: 60px;
                margin-top: 60px;
            }
            @include _mq-down(sm) {
                width: _vw(300, 375);
                height: _vw(340, 375);
                padding: _vw(40, 375) _vw(25, 375) _vw(30, 375);
                margin-top: _vw(90, 375);
            }
            &::before {
                content: '';
                width: _vw(800);
                height: _vw(114);
                background: url("../../assets/img/home/txt_feature.svg") center center / contain no-repeat;
                position: absolute;
                top: _vw(-125);
                left: 0;
                @include _mq-up(xxl) {
                    width: 800px;
                    height: 114px;
                    top: -125px;
                }
                @include _mq-down(sm) {
                    width: _vw(352, 375);
                    height: _vw(50, 375);
                    left: 50%;
                    transform: translateX(-50%);
                    top: _vw(-75, 375);
                }
            }
            &::after {
                content: '“キンビ”の特色';
                font-size: _vw(20);
                font-weight: bold;
                letter-spacing: 0.2em;
                padding-left: 0.2em;
                position: absolute;
                top: _vw(-80);
                left: 0;
                right: 0;
                margin: 0 auto;
                @include _mq-up(xxl) {
                    font-size: 20px;
                    top: -80px;
                }
                @include _mq-down(sm) {
                    font-size: _vw(16, 375);
                    top: _vw(-60, 375);
                }
            }
            & .num {
                display: inline-block;
                position: absolute;
                top: _vw(-35, 1366);
                left: 0;
                right: 0;
                margin: 0 auto;
                font-size: _vw(70, 1366);
                font-weight: bold;
                color: $color-brand;
                @include _mq-up(xxl) {
                    top: -35px;
                    font-size: 70px;
                }
                @include _mq-down(sm) {
                    top: _vw(-27.5, 375);
                    font-size: _vw(55, 375);
                }
            }
            h3 {
                font-size: _vw(25, 1366);
                font-weight: 700;
                line-height: 1.6;
                letter-spacing: 0.15em;
                height: _vw(80, 1366);
                display: flex;
                justify-content: center;
                align-items: center;
                @include _mq-up(xxl) {
                    font-size: 25px;
                    height: 80px;
                }
                @include _mq-down(sm) {
                    height: auto;
                    padding: _vw(9, 375) 0;
                    font-size: _vw(20, 375);
                    line-height: 1.5;
                    letter-spacing: 0.1em;
                }
            }
            & .lead {
                /*display: inline-block;
                width: _vw(570, 1366);
                @include _mq-up(xxl) {
                    width: 570px;
                }
                @include _mq-down(sm) {
                    width: auto;
                }*/
                &.image {
                    margin-top: _vw(40, 1366);
                    @include _mq-up(xxl) {
                        margin-top: 40px;
                    }
                }
                &.text {
                    margin-top: _vw(15);
                    font-size: _vw(14);
                    line-height: (34 / 14);
                    letter-spacing: 0.1em;
                    @include _mq-up(xxl) {
                        font-size: 14px;
                        margin-top: 15px;
                    }
                    @include _mq-down(sm) {
                        font-size: _vw(12, 375);
                        line-height: 2.2;
                        letter-spacing: 0.05em;
                        text-align: justify;
                    }
                }
            }
            .top-btn-column {
                position: absolute;
                bottom: _vw(-30);
                left: 0;
                right: 0;
                margin: 0 auto;
                .top-btn-box {
                    height: _vw(60);
                    padding: _vw(20);
                }
                @include _mq-up(xxl) {
                    bottom: -30px;
                    .top-btn-box {
                        height: 60px;
                        padding: 20px;
                    }
                }
                @include _mq-down(sm) {
                    display: flex;
                    width: _vw(255, 375);
                    bottom: _vw(-30, 375);
                    .top-btn-box {
                        padding: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: _vw(12, 375);
                        height: _vw(60, 375);
                    }
                    .top-btn-box + .top-btn-box {
                        margin-top: 0;
                        margin-left: _vw(10, 375);
                    }
                }
            }
        }
    }
}

/**
* リンクボタン
**/
.btn-center {
    position: absolute;
    left: 0;
    right: 0;
    bottom: _vw(-29, 1366);
    display: inline-block;
    letter-spacing: 0.1em;
    padding-left: 0.1em;
    @include _mq-up(xxl) {
        bottom: -29px;
    }
    @include _mq-down(sm) {
        bottom: _vw(-25, 375);
    }
    a {
        font-size: _vw(14, 1366);
        display: inline-block;
        //background: $color-brand;
        //background: linear-gradient(90deg, #00c6fb 0%, #005bca 100%);
        width: _vw(250, 1366);
        height: _vw(60, 1366);
        padding: _vw(23, 1366) _vw(10, 1366);
        box-sizing: border-box;
        //padding: 1.6em 5.3em;
        @include _mq-up(xxl) {
            font-size: 14px;
            width: 250px;
            height: 60px;
            padding: 23px 10px;
        }
        @include _mq-down(sm) {
            font-size: _vw(12, 375);
            width: _vw(200, 375);
            height: _vw(50, 375);
            padding: _vw(19, 375) _vw(10, 375);
        }
        &.c-gradation {
            color: #fff;
            background: $color-brand;
            background: linear-gradient(135deg, $color-brand 0%, #8c0005 100%);
        }
        &.c-hair {
            color: #00439b;
            background: #fff;
        }
        &.c-beauty {
            color: #ea81a2;
            background: #fff;
        }
    }
}

.btn-white {
    position: absolute;
    left: 0;
    right: 0;
    bottom: _vw(-29, 1366);
    display: inline-block;
    @include _mq-up(xxl) {
        bottom: -29px;
    }
    @include _mq-down(sm) {
        bottom: _vw(-25, 375);
    }
}

.swiper-slide {

}

.sec-Course {
    .slide-top {
        background: #fff;
        .Container {
            @include _mq-down(sm) {
                flex-flow: column;
                //padding-top: 60px; /*ヘッダー分固定*/
            }
        }
        .course {
            width: 50vw;
            height: 100%;
            //height: calc(var(--vh, 1vh) * 100);
            display: flex;
            justify-content: center;
            align-items: center;
            &.course-hb {
                background: #7fa1cd;
                //padding-top: _vw(30, 1366);
                @include _mq-up(xxl) {
                    //padding-top: 30px;
                }
                @include _mq-down(sm) {
                    width: 100vw;
                    //height: calc(50vh - 60px); 必要？
                    height: 50%;
                    padding-top: 60px;
                    box-sizing: border-box;
                }
                & > .text-bg {
                    width: _vw(442, 1366);
                    height: _vw(345, 1366);
                    @include _mq-up(xxl) {
                        width: 442px;
                        height: 345px;
                    }
                    @include _mq-down(sm) {
                        width: _vw(238, 375);
                        height: _vw(202, 375);
                        opacity: 0.7;
                        display: flex;
                        align-items: center;
                        img {
                            height: _vh(202, 667);
                        }
                    }
                }
            }
            &.course-bm {
                background: #f4c0d0;
                //padding-top: _vw(30, 1366);
                @include _mq-down(sm) {
                    width: 100vw;
                    //height: calc(50vh - 60px); 必要？
                    height: 50%;
                    //padding-bottom: 30px;
                    padding-bottom: 60px;
                    box-sizing: border-box;
                }
                & > .text-bg {
                    width: _vw(489, 1366);
                    height: _vw(345, 1366);
                    @include _mq-up(xxl) {
                        height: 345px;
                        width: 489px;
                    }
                    @include _mq-down(sm) {
                        width: _vw(263, 375);
                        height: _vw(202, 375);
                        opacity: 0.7;
                        display: flex;
                        align-items: center;
                        img {
                            height: _vh(202, 667);
                        }
                    }
                }
            }
            .contents__top {
                position: absolute;
                width: _vw(490, 1366);
                @include _mq-down(sm) {
                    width: 100%;
                }
                & > img {
                    width: _vw(180, 375);
                    height: _vw(20, 375);
                    display: inline-block;
                }
                .tit {
                    font-size: _vw(39, 1366);
                    font-weight: 700;
                    letter-spacing: 0.2em;
                    margin-bottom: _vw(15, 1366);
                    padding-top: _vw(30, 1366);
                    @include _mq-up(xxl) {
                        font-size: 39px;
                        margin-bottom: 15px;
                        padding-top: 30px;
                    }
                    @include _mq-down(sm) {
                        font-size: _vw(25, 375);
                        margin-bottom: _vw(10, 375);
                    }
                }
                p {
                    font-size: _vw(15, 1366);
                    letter-spacing: 0.1em;
                    line-height: 2;
                    @include _mq-up(xxl) {
                        font-size: 15px;
                    }
                    @include _mq-down(sm) {
                        font-size: _vw(10, 375);
                        line-height: 1;
                    }
                }
            }
        }
        .course-circle {
            position: absolute;
            width: _vw(170, 1366);
            height: _vw(170, 1366);
            background: $color-brand;
            border-radius: 50%;
            display: flex;
            align-items: center;
            flex-flow: column;
            justify-content: center;
            &::before {
                content: '';
                width: _vw(45);
                height: _vw(10);
                position: absolute;
                top: _vw(21);
                background: url("../../assets/img/home/icon_arrow_gray.svg") center center / contain no-repeat;
            }
            &::after {
                content: '';
                width: _vw(45);
                height: _vw(10);
                position: absolute;
                bottom: _vw(16);
                background: url("../../assets/img/home/icon_arrow_gray.svg") center center / contain no-repeat;
                transform: rotate(180deg);
            }
            .upper {
                font-size: _vw(22);
                font-weight: bold;
                color: #fff;
                letter-spacing: 0.15em;
                padding-left: 0.15em;
                margin-top: _vw(8);
            }
            .lower {
                font-size: _vw(13);
                font-weight: bold;
                color: #fff;
                letter-spacing: 0.2em;
                padding-left: 0.2em;
                line-height: 1.7;
                margin-top: _vw(8);
            }
            @include _mq-up(xxl) {
                width: 170px;
                height: 170px;
                &::before {
                    width: 45px;
                    height: 10px;
                    top: 21px;
                }
                &::after {
                    width: 45px;
                    height: 10px;
                    bottom: 16px;
                }
                .upper {
                    font-size: 22px;
                    margin-top: 8px;
                }
                .lower {
                    font-size: 13px;
                    margin-top: 8px;
                }
            }
            @include _mq-down(sm) {
                display: none;
            }
        }
    }
    .slide-wrap {
        .Container {
            @include _mq-down(sm) {
                flex-flow: column;
            }
        }
        .image-area, .text-area {
            width: 50vw;
            height: 100%;
            //height: calc(var(--vh, 1vh) * 100);
            display: flex;
            justify-content: center;
            align-items: center;
        }
        @include _mq-down(sm) {
            .image-area {
                width: 100vw;
                //height: _vw(280, 667); 必要？
                height: 42%;
            }
            .text-area {
                align-items: stretch;
                width: 100vw;
                //height: _vh(387, 667); 必要？
                height: 58%;
            }
        }
        .band-tips-pc {
            color: #fff;
            font-size: _vw(12, 1366);
            letter-spacing: 0.1em;
            padding: _vw(8, 1366);
            box-sizing: border-box;
            width: _vw(410, 1366);
            //height: 30px;
            background: $color-brand;
            position: relative;
            background: linear-gradient(-45deg, transparent _vw(7), $color-brand 0);
            @include _mq-up(xxl) {
                font-size: 12px;
                padding: 8px;
                width: 410px;
                background: linear-gradient(-45deg, transparent 7px, $color-brand 0);
            }
            @include _mq-down(sm) {
                display: none;
            }
            /*&::after {
                content: '';
                position: absolute;
                right: 0;
                bottom: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 0 _vw(10, 1366) _vw(10, 1366);
                border-color: transparent transparent #000 transparent;
                @include _mq-up(xxl) {
                    border-width: 0 0 10px 10px;
                }
                @include _mq-down(sm) {
                    display: none;
                }
            }*/
        }
        .band-tips-sp {
            color: #fff;
            background: $color-brand;
            @include _mq-up(sm) {
                display: none;
            }
            @include _mq-down(sm) {
                font-size: _vw(8, 375);
                width: _vw(100, 375);
                height: _vw(100, 375);
                border-radius: 50%;
                padding: 2px 0 0 0;
                line-height: 1.7;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column;
                position: absolute;
                top: _vw(78, 375);
                right: _vw(19, 375);
                span.large {
                    display: block;
                    font-size: _vw(12.8, 375);
                    font-weight: bold;
                    margin-bottom: 2px;
                    letter-spacing: 0.15em;
                }
                span.mini {
                    display: inline-block;
                    letter-spacing: 0.2em;
                }
            }
        }
        .course-tips {
            display: block;
            color: #fff;
            font-size: _vw(12.5, 1366);
            font-weight: normal;
            width: _vw(205, 1366);
            padding: _vw(14, 1366) 0;
            box-sizing: border-box;
            background: #5f6469;
            letter-spacing: 0.15em;
            @include _mq-up(xxl) {
                font-size: 12.5px;
                width: 205px;
                padding: 14px 0;
            }
            @include _mq-down(sm) {
                font-size: _vw(12, 375);
                font-weight: bold;
                width: _vw(250, 375);
                padding: _vw(7, 375) 0;
                letter-spacing: 0.2em;
                margin-top: _vw(15, 375);
            }
        }
        .contents__box {
            position: relative;
            width: _vw(410, 1366);
            height: _vw(480, 1366);
            @include _mq-up(xxl) {
                width: 410px;
                height: 480px;
            }
            @include _mq-down(sm) {
                width: _vw(300, 375);
                height: auto;
                padding: _vh(65, 667) 0;
            }
            h3 {
                font-size: _vw(37, 1366);
                font-weight: bold;
                display: flex;
                justify-content: space-between;
                align-items: center;
                letter-spacing: 0.2em;
                margin-top: _vw(54, 1366);
                margin-bottom: _vw(42, 1366);
                color: #fff;
                @include _mq-up(xxl) {
                    font-size: 37px;
                    margin-top: 50px;
                    margin-bottom: 42px;
                }
                @include _mq-down(sm) {
                    flex-flow: column;
                    font-size: _vw(27, 375);
                    margin-top: 0;
                    margin-bottom: _vw(20, 375);
                }
            }
            p {
                font-size: _vw(15, 1366);
                text-align: justify;
                color: #fff;
                line-height: 2.3333;
                letter-spacing: 0.1em;
                @include _mq-up(xxl) {
                    font-size: 15px;
                }
                @include _mq-down(sm) {
                    font-size: _vw(12, 375);
                    line-height: 1.8333;
                }
            }
        }
    }
    .Advance {
        @include _mq-up(sm) {
            background: url("../../assets/img/home/bg_course_hb-ad_pc.jpg") center center / cover no-repeat;
        }
        @include _mq-down(sm) {
            background: url("../../assets/img/home/bg_course_hb-ad_sp.jpg") center center / cover no-repeat;
        }
        .Container {
            @include _mq-up(sm) {
                flex-direction: row-reverse;
            }
            @include _mq-down(sm) {
                .contents__box {
                    height: _vw(300, 667);
                    p {
                        text-align: center;
                        letter-spacing: 0.075em;
                    }
                    /*.btn-center {
                        position: static;
                    }*/
                }
            }
            .Inr {
                h4 {
                    position: absolute;
                    width: _vw(574, 1366);
                    height: _vw(372, 1366);
                    left: _vw(-105, 1366);
                    top: _vw(-85, 1366);
                    @include _mq-up(xxl) {
                        width: 574px;
                        height: 372px;
                        left: -105px;
                        top: -85px;
                    }
                    @include _mq-down(sm) {
                        width: _vw(288, 375);
                        height: _vh(183, 667);
                        left: _vw(-192, 375);
                        top: _vh(-172, 667);
                    }
                }
            }
        }
    }
    .Advance .Container { //スライド前
        .text-area {
            background: #7fa1cd;
            position: relative;
            &::after {
                content: '';
                background: #7fa1cd;
                width: 50vw;
                height: 100%;
                position: absolute;
                right: -50vw;
                top: 0;
                transition: 1s;
            }
            @include _mq-down(sm) {
                &::after {
                    width: 0;
                    right: 0;
                }
            }
        }
    }
    .Advance.swiper-slide-active .Container { //スライド後
        .text-area::after {
            width: 0;
            right: 0;
        }
    }
    .Working {
        @include _mq-up(sm) {
            background: url("../../assets/img/home/bg_course_hb-ws_pc.jpg") center center / cover no-repeat;
        }
        @include _mq-down(sm) {
            background: url("../../assets/img/home/bg_course_hb-ws_sp.jpg") center top / cover no-repeat;
        }
        .Container {
            @include _mq-down(sm) {
                .contents__box {
                    height: _vw(300, 667);
                    p {
                        text-align: center;
                    }
                }
            }
            .Inr {
                h4 {
                    position: absolute;
                    width: _vw(574, 1366);
                    height: _vw(372, 1366);
                    left: _vw(-432, 1366);
                    top: _vw(-85, 1366);
                    @include _mq-up(xxl) {
                        width: 574px;
                        height: 372px;
                        left: -432px;
                        top: -85px;
                    }
                    @include _mq-down(sm) {
                        width: _vw(288, 375);
                        height: _vh(183, 667);
                        left: _vw(-192, 375);
                        top: _vh(-172, 667);
                    }
                }
            }
        }
    }
    .Working .Container { //スライド前
        .text-area {
            background: #7fa1cd;
            position: relative;
            &::after {
                content: '';
                background: #7fa1cd;
                width: 50vw;
                height: 100%;
                position: absolute;
                left: -50vw;
                top: 0;
                transition: 1s;
            }
            @include _mq-down(sm) {
                &::after {
                    width: 0;
                    left: 0;
                }
            }
        }
    }
    .Working.swiper-slide-active .Container { //スライド後
        .text-area::after {
            width: 0;
            left: 0;
        }
    }
    .Beauty {
        @include _mq-up(sm) {
            background: url("../../assets/img/home/bg_course_bm_pc.jpg") center center / cover no-repeat;
        }
        @include _mq-down(sm) {
            background: url("../../assets/img/home/bg_course_bm_sp.jpg") center top / contain no-repeat;
        }
        .Container {
            @include _mq-up(sm) {
                flex-direction: row-reverse;
            }
            .contents__box {
                h3 {
                    display: block;
                    letter-spacing: 0em;
                    text-align: justify;
                    text-align-last: justify;
                    text-justify: inter-ideograph;
                    /*@include _mq-down(sm) {
                        text-align: center;
                        text-align-last: center;
                    }*/
                    @include _mq-down(sm) {
                        letter-spacing: 0.1em;
                        margin-top: _vw(7, 375);
                        text-align: center;
                        text-align-last: center;
                    }
                }
                p {
                    text-align: justify;
                    text-align-last: center;
                }
                @include _mq-down(sm) {
                    height: _vh(169, 667);
                }
            }
            .Inr {
                h4 {
                    position: absolute;
                    width: _vw(691, 1366);
                    height: _vw(367, 1366);
                    left: _vw(-108, 1366);
                    top: _vw(-34, 1366);
                    @include _mq-up(xxl) {
                        width: 691px;
                        height: 367px;
                        left: -108px;
                        top: -34px;
                    }
                    @include _mq-down(sm) {
                        width: _vw(347, 375);
                        height: _vw(182, 375);
                        left: _vw(-191, 375);
                        top: _vh(-142, 667);
                    }
                }
            }
        }
    }
    .Beauty .Container { //スライド前
        .text-area {
            background: #ea81a2;
            position: relative;
            &::after {
                content: '';
                background: #ea81a2;
                width: 50vw;
                height: 100%;
                position: absolute;
                right: -50vw;
                top: 0;
                transition: 1s;
            }
            @include _mq-down(sm) {
                &::after {
                    width: 0;
                    right: 0;
                }
            }
        }
    }
    .Beauty.swiper-slide-active .Container { //スライド後
        .text-area::after {
            width: 0;
            right: 0;
        }
    }
}

.sec-Support {
    text-align: center;
    @include _mq-up(sm) {
        background: url("../../assets/img/home/bg_support_pc.jpg") center center / cover no-repeat;
    }
    @include _mq-down(sm) {
        background: url("../../assets/img/home/bg_support_sp.jpg") center center / cover no-repeat;
    }
    .tit {
        font-size: _vw(50);
        line-height: 1.4;
        font-weight: bold;
        letter-spacing: 0.15em;
        @include _mq-up(xxl) {
            font-size: 50px;
        }
        @include _mq-down(sm) {
            font-size: _vw(25, 375);
            line-height: 1.65;
        }
    }
    .btn-box-support {
        margin-top: _vw(50);
        @include _mq-up(xxl) {
            margin-top: 50px;
        }
        @include _mq-down(sm) {
            margin-top: _vw(25, 375);
        }
    }
}

.sec-Facility {
    @include _mq-up(sm) {
        background: url("../../assets/img/home/bg_facility_pc.jpg") center center / cover no-repeat;
    }
    @include _mq-down(sm) {
        background: #fff;
        .Inr {
            position: static;
            width: auto;
            height: auto;
        }
        .Container {
            //padding-top: 60px; /*ヘッダー分*/
        }
    }
    .contents__box {
        text-align: center;
        position: absolute;
        left: _vw(24);
        top: _vw(-248);
        @include _mq-down(sm) {
            position: static;
        }
    }
    .tit {
        font-size: _vw(40);
        font-weight: bold;
        letter-spacing: 0.15em;
        padding-left: 0.15em;
        position: relative;
        padding-top: _vw(4);
        padding-bottom: _vw(18);
        margin-bottom: _vw(12);
        @include _mq-up(xxl) {
            font-size: 40px;
            padding-top: 4px;
            padding-bottom: 18px;
            margin-bottom: 12px;
        }
        @include _mq-down(sm) {
            font-size: _vw(25, 375);
            padding-top: _vw(8, 375);
            padding-bottom: _vw(10, 375);
            margin-bottom: _vw(6, 375);
        }
        &:before {
            content: '';
            background: url("../../assets/img/home/txt_facility.png") center center / contain no-repeat;
            width: _vw(528);
            height: _vw(142);
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            @include _mq-up(xxl) {
                width: 528px;
                height: 142px;
            }
            @include _mq-down(sm) {
                width: _vw(382, 375);
                height: _vw(102, 375);
            }
        }
        &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            width: _vw(30);
            height: _vw(2);
            margin: 0 auto;
            background: $color-brand;
            @include _mq-up(xxl) {
                width: 30px;
                height: 2px;
            }
            @include _mq-down(sm) {
                width: _vw(20, 375);
                height: 1px;
            }
        }
    }
    .text {
        font-size: _vw(16);
        line-height: 2;
        letter-spacing: 0.1em;
        padding-left: 0.1em;
        @include _mq-up(xxl) {
            font-size: 16px;
        }
        @include _mq-down(sm) {
            font-size: _vw(12, 375);
        }
    }
    .btn-box-facility {
        margin-top: _vw(25);
        @include _mq-up(xxl) {
            margin-top: 25px;
        }
        @include _mq-down(sm) {
            margin-top: _vw(25, 375);
        }
    }
    .image-upper {
        width: _vw(450);
        margin-bottom: _vw(46);
        @include _mq-up(xxl) {
            width: 450px;
            margin-bottom: 46px;
        }
        @include _mq-down(sm) {
            width: _vw(295, 375);
            margin-bottom: _vw(72, 375);
        }
    }
    .image-lower {
        width: _vw(450);
        margin-top: _vw(50);
        @include _mq-up(xxl) {
            width: 450px;
            margin-top: 50px;
        }
        @include _mq-down(sm) {
            width: _vw(295, 375);
            margin-top: _vw(60, 375);
        }
    }
}

.slide-Facility {
    @include _mq-up(sm) {
        background: url("../../assets/img/home/bg_facility_pc.jpg") center center / cover no-repeat;
    }
    @include _mq-down(sm) {
        background: #fff;
        .Inr {
            position: static;
            width: auto;
            height: auto;
        }
        .Container {
            //padding-top: 60px; /*ヘッダー分*/
        }
    }
    .f-contents__box {
        text-align: center;
        position: absolute;
        left: _vw(24);
        top: _vw(-248);
        @include _mq-down(sm) {
            position: static;
        }
    }
    .tit {
        font-size: _vw(40);
        font-weight: bold;
        letter-spacing: 0.15em;
        padding-left: 0.15em;
        position: relative;
        padding-top: _vw(4);
        padding-bottom: _vw(18);
        margin-bottom: _vw(12);
        @include _mq-up(xxl) {
            font-size: 40px;
            padding-top: 4px;
            padding-bottom: 18px;
            margin-bottom: 12px;
        }
        @include _mq-down(sm) {
            font-size: _vw(25, 375);
            padding-top: _vw(8, 375);
            padding-bottom: _vw(10, 375);
            margin-bottom: _vw(6, 375);
        }
        &:before {
            content: '';
            background: url("../../assets/img/home/txt_facility.png") center center / contain no-repeat;
            width: _vw(528);
            height: _vw(142);
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            @include _mq-up(xxl) {
                width: 528px;
                height: 142px;
            }
            @include _mq-down(sm) {
                width: _vw(382, 375);
                height: _vw(102, 375);
            }
        }
        &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            width: _vw(30);
            height: _vw(2);
            margin: 0 auto;
            background: $color-brand;
            @include _mq-up(xxl) {
                width: 30px;
                height: 2px;
            }
            @include _mq-down(sm) {
                width: _vw(20, 375);
                height: 1px;
            }
        }
    }
    .text {
        font-size: _vw(16);
        line-height: 2;
        letter-spacing: 0.1em;
        padding-left: 0.1em;
        @include _mq-up(xxl) {
            font-size: 16px;
        }
        @include _mq-down(sm) {
            font-size: _vw(12, 375);
        }
    }
    .btn-box-facility {
        margin-top: _vw(25);
        @include _mq-up(xxl) {
            margin-top: 25px;
        }
        @include _mq-down(sm) {
            margin-top: _vw(25, 375);
        }
    }
    .image-upper {
        width: _vw(450);
        margin-bottom: _vw(46);
        @include _mq-up(xxl) {
            width: 450px;
            margin-bottom: 46px;
        }
        @include _mq-down(sm) {
            width: _vw(295, 375);
            margin-bottom: _vw(72, 375);
        }
    }
    .image-lower {
        width: _vw(450);
        margin-top: _vw(50);
        @include _mq-up(xxl) {
            width: 450px;
            margin-top: 50px;
        }
        @include _mq-down(sm) {
            width: _vw(295, 375);
            margin-top: _vw(60, 375);
        }
    }
}

.sec-Opencampus {
    @include _mq-up(sm) {
        background: url("../../assets/img/home/bg_opencampus_pc.jpg") center center / cover no-repeat;
    }
    @include _mq-down(sm) {
        background: url("../../assets/img/home/bg_opencampus_sp.jpg") center center / cover no-repeat;
    }
    @include _mq-down(sm) {
        .Container {
            //padding-top: 60px; /*ヘッダー分*/
        }
    }
    .contents__box {
        @include _mq-down(sm) {
            padding-top: 60px;
        }
        .image {
            width: _vw(980);
            display: block;
            @include _mq-up(xxl) {
                width: 980px;
            }
            @include _mq-down(sm) {
                width: _vw(344, 375);
                margin: 0 auto;
            }
        }
        p {
            @include _mq-down(sm) {
                font-size: _vw(13, 375);
            }
        }
        @include _mq-down(sm) {
            .image-sp {
                width: _vw(344, 375);
            }
        }
    }
    .btn-box-oc {
        display: flex;
        justify-content: center;
        //gap: _vw(40, 1366);
        margin-top: _vw(35, 1366);
        @include _mq-up(xxl) {
            gap: 40px;
            margin-top: 35px;
        }
        @include _mq-down(sm) {
            //gap: _vw(14, 375);
            flex-flow: column;
            align-items: center;
            margin-top: _vw(20, 375);
            margin-bottom: _vw(20, 375);
        }
    }
    .oc-btn {
        display: block;
        width: _vw(250, 1366);
        height: _vw(60, 1366);
        border-radius: _vw(30, 1366);
        color: #fff;
        text-align: center;
        font-size: _vw(14, 1366);
        font-weight: 600;
        padding: _vw(23, 1366);
        @include _mq-down(sm) {
            width: _vw(210, 375);
            height: _vw(42, 375);
            border-radius: _vw(21, 375);
            font-size: _vw(12, 375);
            padding: _vw(15, 375);
        }
        &.oc {
            background: $color-brand;
            @include _mq-up(md) {
                &:hover {
                    background: rgba($color-brand, 0.7);
                }
            }
        }
        &.choi {
            background: orange;
            @include _mq-up(md) {
                margin-left: _vw(40);
                &:hover {
                    background: rgba(orange, 0.7);
                }
            }
            @include _mq-down(sm) {
                margin-top: 10px;
            }
        }
    }
}

.oc-btn {

}

.sec-HighSchool {
    @include _mq-up(sm) {
        background: url("../../assets/img/home/bg_highschool_pc.jpg") center center / cover no-repeat;
    }
    @include _mq-down(sm) {
        background: url("../../assets/img/home/bg_highschool_sp.jpg") center center / cover no-repeat;
        background-color: #fff;
    }

    .Container {
        .contents__box {
            width: _vw(500, 1366);
            height: _vw(300, 1366);
            background: $color-brand;
            padding: _vw(35, 1366) _vw(40, 1366) _vw(40, 1366);
            &::after {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 0 _vw(30, 1366) _vw(30, 1366);
                border-color: transparent transparent rgba(0,0,0,.2) transparent;
                position: absolute;
                right: _vw(15, 1366);
                bottom: _vw(15, 1366);
            }
            @include _mq-up(xxl) {
                width: 500px;
                height: 300px;
                padding: 35px 40px 40px;
                &::after {
                    border-width: 0 0 30px 30px;
                    right: 15px;
                    bottom: 15px;
                }
            }
            @include _mq-down(sm) {
                width: 100vw;
                height: _vh(254, 667);
                padding: 0 0 _vh(25, 667) 0;
                display: flex;
                flex-flow: column;
                justify-content: center;
                position: absolute;
                bottom: 0;
                &::after {
                    border-width: 0 0 _vw(30, 375) _vw(30, 375);
                    right: _vw(15, 375);
                    bottom: _vw(15, 375);
                }
            }
            h3 {
                font-size: _vw(32, 1366);
                font-weight: 700;
                text-align: center;
                color: #fff;
                line-height: 1.4286;
                letter-spacing: 0.15em;
                @include _mq-up(xxl) {
                    font-size: 32px;
                }
                @include _mq-down(sm) {
                    font-size: _vw(26, 375);
                    line-height: 1.3846;
                }
            }
            p {
                color: #fff;
                font-size: _vw(15, 1366);
                text-align: center;
                line-height: 2;
                margin-top: _vw(20, 1366);
                letter-spacing: 0.2em;
                @include _mq-up(xxl) {
                    font-size: 15px;
                    margin-top: 20px;
                }
                @include _mq-down(sm) {
                    font-size: _vw(12, 375);
                    margin-top: _vw(10, 375);
                    letter-spacing: 0.2em;
                }
            }
        }
    }
}

.sec-BisenTube {
    @include _mq-up(sm) {
        background: url("../../assets/img/home/bg_bisentube_pc.jpg") center center / cover no-repeat;
    }
    @include _mq-down(sm) {
        background: url("../../assets/img/home/bg_bisentube_sp.jpg") center center/ cover no-repeat;
        background-color: #fff;
    }
    .contents__box {
        text-align: center;
        @include _mq-down(sm) {
            margin-top: _vw(110, 375);
        }
        .tit {
            font-size: _vw(30);
            font-weight: bold;
            letter-spacing: 0.15em;
            position: relative;
            padding-bottom: _vw(14);
            margin-bottom: _vw(12);
            line-height: 1.5333;
            &::after {
                content: '';
                width: _vw(30);
                height: _vw(2);
                background: $color-brand;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
            @include _mq-up(xxl) {
                font-size: 30px;
                padding-bottom: 14px;
                margin-bottom: 12px;
                &::after {
                    width: 30px;
                    height: 2px;
                }
            }
            @include _mq-down(sm) {
                font-size: _vw(20, 375);
                padding-bottom: _vw(10, 375);
                margin-bottom: _vw(8, 375);
                margin-right: _vw(25, 375);
                &::after {
                    width: _vw(20, 375);
                    height: 1px;
                }
            }
        }
        .text {
            font-size: _vw(16);
            line-height: 2;
            letter-spacing: 0.1em;
            @include _mq-up(xxl) {
                font-size: 16px;
            }
            @include _mq-down(sm) {
                font-size: _vw(12, 375);
                margin-right: _vw(25, 375);
            }
        }
        .btn-box-bisentube {
            margin-top: _vw(25);
            @include _mq-up(xxl) {
                margin-top: 25px;
            }
            @include _mq-down(sm) {
                margin-top: _vw(200, 375);
            }
        }
    }
}

.sec-Admission {
    @include _mq-up(sm) {
        background-image: url("../../assets/img/home/bg_admission_pc.png");
        background-size: 10px;
        background-color: #fff;
    }
    @include _mq-down(sm) {
        background: url("../../assets/img/home/bg_admission_pc.png");
        background-size: 10px;
        background-color: #fff;
    }
    .top-wrap {
        .contents__box {
            width: _vw(800, 1366);
            height: _vw(409, 1366);
            background: rgba(255,255,255,0.8);
            position: relative;
            @include _mq-up(xxl) {
                width: 800px;
                height: 409px;
            }
            @include _mq-down(sm) {
                width: _vw(290, 375);
                height: _vw(400, 375);
            }
            .title {
                position: absolute;
                transform: translateY(-50%);
                left: 0;
                right: 0;
                margin: 0 auto;

                color: $color-brand;
                font-size: _vw(27, 1366);
                font-weight: 500;
                letter-spacing: 0.07em;
                @include _mq-up(xxl) {
                    font-size: 27px;
                }
                @include _mq-down(sm) {
                    font-size: _vw(19, 375);
                }
            }
            .bisen {
                .jp {
                    font-size: _vw(10, 1366);
                    letter-spacing: 1em;
                    @include _mq-up(xxl) {
                        font-size: 10px;
                    }
                    @include _mq-down(sm) {
                        font-size: _vw(10, 375);
                    }
                }
                .en {
                    font-size: _vw(45, 1366);
                    color: $color-brand;
                    font-weight: 500;
                    margin-top: _vw(12, 1366);
                    @include _mq-up(xxl) {
                        font-size: 45px;
                        margin-top: 12px;
                    }
                    @include _mq-down(sm) {
                        font-size: _vw(30, 375);
                        line-height: _vw(40, 375);
                        margin-top: _vw(7, 375);
                    }
                }
            }
            h2 {
                font-size: _vw(30, 1366);
                font-weight: bold;
                letter-spacing: 0.15em;
                position: relative;
                margin-top: _vw(13, 1366);
                padding-bottom: _vw(22, 1366);
                margin-bottom: _vw(16, 1366);
                &::after {
                    content: '';
                    width: _vw(30, 1366);
                    height: _vw(2, 1366);
                    background: $color-brand;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }
                @include _mq-up(xxl) {
                    font-size: 30px;
                    margin-top: 13px;
                    padding-bottom: 22px;
                    margin-bottom: 16px;
                }
                @include _mq-down(sm) {
                    font-size: _vw(23, 375);
                    margin-top: _vw(7, 375);
                    padding-bottom: _vw(22, 375);
                    margin-bottom: _vw(16, 375);
                    &::after {
                        width: _vw(30, 375);
                        height: _vw(2, 375);
                    }
                }
            }
            .text {
                font-size: _vw(16, 1366);
                line-height: 2;
                letter-spacing: 0.1em;
                @include _mq-up(xxl) {
                    font-size: 16px;
                }
                @include _mq-down(sm) {
                    font-size: _vw(13, 375);
                }
            }
        }
    }
    .slide-wrap {
        .Container {
            @include _mq-down(sm) {
                padding-top: _vw(30, 375);
            }
        }
        .contents__box {
            width: _vw(800);
            height: _vw(409);
            position: relative;
            @include _mq-up(xxl) {
                width: 800px;
                height: 409px;
            }
            @include _mq-down(sm) {
                width: _vw(290, 375);
                height: _vw(400, 375);
            }
            &--upper {
                background: rgba(255,255,255,0.8);
                height: _vw(30);
                width: _vw(770);
                @include _mq-up(xxl) {
                    height: 30px;
                    width: 770px;
                }
                @include _mq-down(sm) {
                    height: _vw(25, 375);
                    width: _vw(265, 375);
                }
            }
            &--inner {
                background: rgba(255,255,255,0.8);
                height: _vw(379);
                padding-top: _vw(5);
                box-shadow: _vw(10) _vw(10) _vw(10) 0 rgba(0, 0, 0, .15);
                @include _mq-up(xxl) {
                    height: 379px;
                    padding-top: 5px;
                    box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, .15);
                }
                @include _mq-down(sm) {
                    height: _vw(375, 375);
                    padding-top: _vw(5, 375);
                    box-shadow: _vw(7, 375) _vw(7, 375) _vw(7, 375) 0 rgba(0, 0, 0, .15);
                }
            }
            //padding: _vw(33) 0 _vw(60);
            box-sizing: border-box;
            position: relative;
            &::before {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: _vw(30) 0 0 _vw(30);
                border-color: transparent transparent transparent #b2b2b2;
                position: absolute;
                top: 0;
                right: 0;
                @include _mq-up(xxl) {
                    border-width: 30px 0 0 30px;
                }
                @include _mq-down(sm) {
                    border-width: _vw(25, 375) 0 0 _vw(25, 375);
                }
            }
            &::after {
                content: '';
                width: _vw(800);
                height: _vw(34);
                background: url("../../assets/img/home/txt_adm_information.svg") center center / contain no-repeat;
                position: absolute;
                top: _vw(-50);
                left: 0;
                @include _mq-up(xxl) {
                    width: 800px;
                    height: 34px;
                    top: -50px;
                }
                @include _mq-down(sm) {
                    width: _vw(285, 375);
                    height: _vw(12, 375);
                    top: _vw(-25, 375);
                }
            }
            .icon {
                width: _vw(90);
                margin: 0 auto;
                @include _mq-up(xxl) {
                    width: 90px;
                }
                @include _mq-down(sm) {
                    width: _vw(60, 375);
                }
            }
            .subhead {
                color: $color-brand;
                font-size: _vw(25);
                line-height: 1.6;
                font-weight: bold;
                letter-spacing: 0.1em;
                text-align: center;
                margin-top: _vw(10);
                @include _mq-up(xxl) {
                    font-size: 25px;
                    margin-top: 10px;
                }
                @include _mq-down(sm) {
                    font-size: _vw(23, 375);
                    margin-top: _vw(15, 375);
                }
            }
            .list {
                width: _vw(340);
                margin: _vw(15) auto 0;
                color: $color-brand;
                @include _mq-up(xxl) {
                    width: 340px;
                    margin: 15px auto 0;
                }
                @include _mq-down(sm) {
                    width: _vw(230, 375);
                    margin-top: _vw(10, 375);
                }
                li {
                    border-bottom: 1px solid #dbdbdb;
                    font-size: _vw(16);
                    text-align: left;
                    letter-spacing: 0.1em;
                    line-height: 1;
                    padding-top: _vw(17, 1366);
                    padding-bottom: _vw(17, 1366);
                    padding-left: 1.2em; //デフォルトは1.1em
                    /*@include _mq-down(sm) {
                        height: _vw(50, 375);
                        font-size: _vw(15, 375);
                        padding-top: _vw(17, 375);
                        padding-bottom: _vw(17, 375);
                    }*/
                    @include _mq-up(xxl) {
                        font-size: 16px;
                        padding-top: 17px;
                        padding-bottom: 17px;
                    }
                    @include _mq-down(sm) {
                        height: auto;
                        font-size: _vw(13, 375);
                        padding-top: _vw(18, 375);
                        padding-bottom: _vw(18, 375);
                    }
                }
            }
        }
        &.slide07 {
            .contents__box {
                .subhead {
                    margin-top: _vw(35);
                    @include _mq-up(xxl) {
                        margin-top: 35px;
                    }
                    @include _mq-down(sm) {
                        line-height: 1.4783;
                        padding-left: 12px;
                    }
                }
                .list {
                    margin-top: _vw(40);
                    @include _mq-up(xxl) {
                        margin-top: 40px
                    }
                    li {
                        padding-left: 0.1em;
                    }
                }
                @include _mq-down(sm) {
                }
            }
        }
    }
}
