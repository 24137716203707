// Object - swiper

#fullpage {

.swiper {
    height: 100%;
    //background: #fff;
}

.swiper-wrapper {
  width: 100%;
  height: 100%;
  //height: calc(var(--vh, 1vh) * 100);
}

.swiper-slide {
  //color: #ffffff;
  width: 100%;
  height: 100%;
  //height: calc(var(--vh, 1vh) * 100);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  margin: 0 0 0 10px;
  background: #333;
}
.swiper-pagination-bullet:first-child {
  margin: 0 !important;
}

.swiper-slide:nth-child(3n + 1) {
  /*1、4、7、3n+1枚目の背景色 */
  //background-color: #ccc;
}

.swiper-slide:nth-child(3n + 2) {
  /*2、5、8、3n+2枚目の背景色 */
  //background-color: #ddd;
}

.swiper-slide:nth-child(3n + 3) {
  /*3、6、9、3n+3枚目の背景色 */
  //background-color: #eee;
}

.swiper-button-next, .swiper-button-prev{
    position: absolute;
    top: auto;
    bottom: 30px;
    width: 55px;
    height: auto;
    margin-top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 10;
    cursor: pointer;
    //background-size: 27px 44px;
    //background-position: center;
    //background-repeat: no-repeat;
}

.swiper-button-next {
    background: url("../../assets/img/common/arrow-r-black.svg") right center / 7px 11px no-repeat;
    left: 200px;
    padding-right: 20px;
    line-height: 1;
    text-align: right;
    &::after {
        content:'次へ';
        line-height: 1;
    }
}

.swiper-button-prev {
    background: url("../../assets/img/common/arrow-l-black.svg") left center / 7px 11px no-repeat;
    right: 200px;
    padding-left: 20px;
    &::after {
        content:'前へ';
    }
}

.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 30px;
}


}
